// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckboxWrapper_wrapper__89\\+D5 {
  display: flex;
  margin-bottom: 30px;
  cursor: pointer;
}
.CheckboxWrapper_wrapper__89\\+D5.CheckboxWrapper_active__mR4sJ svg {
  opacity: 1;
}

.CheckboxWrapper_checkbox__V--\\+6 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #e3e5fa;
}
.CheckboxWrapper_checkbox__V--\\+6 svg {
  transition: all 0.2s;
  opacity: 0;
}

.CheckboxWrapper_name__5eAdG {
  margin-left: 4px;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/shared/CheckboxWrapper/CheckboxWrapper.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AAEI;EACE,UAAA;AAAN;;AAKA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AAFF;AAIE;EACE,oBAAA;EACA,UAAA;AAFJ;;AAKA;EACE,gBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAFF","sourcesContent":[".wrapper {\n  display: flex;\n  margin-bottom: 30px;\n  cursor: pointer;\n\n  &.active {\n    svg {\n      opacity: 1;\n    }\n  }\n}\n\n.checkbox {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 20px;\n  height: 20px;\n  border-radius: 6px;\n  border: 1px solid #e3e5fa;\n\n  svg {\n    transition: all .2s;\n    opacity: 0;\n  }\n}\n.name {\n  margin-left: 4px;\n  color: #343a40;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CheckboxWrapper_wrapper__89+D5`,
	"active": `CheckboxWrapper_active__mR4sJ`,
	"checkbox": `CheckboxWrapper_checkbox__V--+6`,
	"name": `CheckboxWrapper_name__5eAdG`
};
export default ___CSS_LOADER_EXPORT___;
