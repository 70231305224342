import axios from "axios";
import { apiRoutes } from "../../../utils/constants";
import { popUp } from "../../../features/popUp/PopUp";
import { deleteAuthToken } from "../../sessionStorage/deleteAuthToken";

const tokenRefreshApi = async (refreshToken: any) => {
  // popUp({ type: "info", message: "Loading..." });
  // return await axios
  const data = {
    refreshToken: refreshToken,
  };
  return await axios[apiRoutes.REFRESH.request](
    apiRoutes.REFRESH.url,
    JSON.stringify(data),
    {
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response: any) => {
      if (response) {
        return response;
      }
    })
    .catch((error: any) => {
      console.log("error: ", error);
      deleteAuthToken();
      window.location.href = "/login";
      popUp({ type: "failure", message: "Oh, something went wrong!" });
    });
};

export default tokenRefreshApi;
