import React, { FC } from "react";
import s from "./MainLayout.module.scss";
import { LogoWithText } from "../../assets/svg/LogoWithText";
import { Logout } from "../../assets/svg/Logout";
import { useNavigate } from "react-router-dom";
import { ProjectMenu } from "../ProjectMenu/ProjectMenu";
import { deleteAuthToken } from "../../processes/sessionStorage/deleteAuthToken";
import { PAGES } from "../../utils/constants";
import { ModalFrame } from "../../processes/modals/ModalFrame/ModalFrame";
import { useDispatch, useSelector } from "react-redux";
import { getProjectById } from "../../redux/selectors/selectors";
import { attributesNamesT } from "../../redux/reducers/types";
import { setActiveProject } from "../../redux/actions";
import { ProjectsIcon } from "../../assets/svg/ProjectsIcon";
import { useAuthInterval } from "./hooks/useAuthInterval";
import { useAttributesWithApi } from "./hooks/useAttributesWithApi";

type MainLayoutT = {
  children: any;
};

export const MainLayout: FC<MainLayoutT> = ({ ...props }) => {
  const { children } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const parts: any = pathname.split("/");
  const idProject = parts[1];
  const idTab: attributesNamesT = parts[2];
  const project: any = useSelector(getProjectById(idProject));
  useAuthInterval();
  useAttributesWithApi(idProject, idTab, project, dispatch);

  function goToProjects() {
    navigate(PAGES.PROJECTS);
    dispatch(setActiveProject({ id: undefined }));
  }
  return (
    <>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.sidebar}>
            <div className={s.info}>
              <div className={s.logo} onClick={() => goToProjects()}>
                <LogoWithText />
              </div>
              <div className={s.navigate}>
                <div className={s.link} onClick={() => goToProjects()}>
                  <ProjectsIcon />
                  Projects
                </div>
              </div>
            </div>
            <div
              className={s.logout}
              onClick={() => {
                deleteAuthToken();
                dispatch(setActiveProject({ id: undefined }));
                navigate(PAGES.LOGIN);
              }}
            >
              <Logout />
              <span>LogOut</span>
            </div>
          </div>
          <div className={s.body}>
            <div className={s.child}>
              <ProjectMenu />
              {children}
            </div>
          </div>
        </div>
      </div>
      <ModalFrame />
    </>
  );
};
