import axios from 'axios';

const API = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API,
  responseType: 'json',
});

axiosInstance.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    config.headers['Content-Type'] = 'application/json';
  }

  const authInfo: any = sessionStorage.getItem("authInfo");
  const data: any = JSON.parse(authInfo);
  const token = data?.token || data?.refreshToken;
  if(token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;