import ReactDOM from "react-dom/client";
import "./utils/styles/fonts.css";
import "./utils/styles/index.scss";
import "./utils/styles/notiflix.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import WebSocketComponent from "./features/WebSocketComponent/WebSocketComponent";
import { MainRouter } from "./routers/MainRouter";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <MainRouter />
    <WebSocketComponent />
  </Provider>
);
