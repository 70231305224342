import { FC, useEffect, useState } from "react";
import s from "./ProductEditAttr.module.scss";
import { BackArrow } from "../../assets/svg/BackArrow";
import { ToggleBtn } from "../../shared/ToggleBtn/ToggleBtn";
import {
  toggleEnableAttribute,
  toggleEnableAttributeOption,
} from "../../features/functions/toggleEnableAttribute";
import { useDispatch, useSelector } from "react-redux";
import { ButtonWrapper } from "../../shared/ButtonWrapper/ButtonWrapper";
import { v4 as uuidv4 } from "uuid";

import {
  getActiveProject,
  getAllItemAttribute,
  getCategories,
  getItemAttributeById,
} from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { changeShowModal, setActiveProductId, setItemsAttribute } from "../../redux/actions";
import getAttributesApi from "../../processes/api/attributes/getAttributesApi";
import { optionsTag } from "../../utils/constants";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";
import { IdPlace } from "../../shared/IdPlace/IdPlace";
import { loadItemProductInState } from "../../features/functions/loadItemProductInState";
import { loadItemsProductInState } from "../../features/functions/loadItemsProductInState";
import changeAttributeApi from "../../processes/api/attributes/changeAttributeApi";
import { Plus } from "../../assets/svg/Plus";
import { Close } from "../../assets/svg/Close";
import { CategoryProduct } from "./components/CategoryProduct/CategoryProduct";
import { RenderProduct } from "./components/RenderProduct/RenderProduct";
import { AllRenders } from "./components/AllRenders/AllRenders";
import { CombiningOptions } from "./components/CombiningOptions/CombiningOptions";

type ProductEditAttrT = {
  setEditAttr: any;
  editAttr: any;
  optionData: any;
  stageData: any;
};

export const ProductEditAttr: FC<ProductEditAttrT> = ({ ...props }) => {
  const { setEditAttr, editAttr, optionData, stageData } = props;
  const dispatch = useDispatch();
  const productData = useSelector(
    getItemAttributeById({ name: "products", id: editAttr.id })
  );
  const [activeModal, setActiveModal]: any = useState(false);
  const [allOptionsProductData, setAllOptionsProductData] = useState([]);

  const pathname = window.location.pathname;
  const parts: any = pathname.split("/");
  const idProject = parts[1];
  const selectList: any = [
    {
      label: "Option",
      value: "options",
      disable: optionData.length === 0 ? true : false,
      data: optionData,
    },
    {
      label: "Stage",
      value: "stages",
      disable: stageData.length === 0 ? true : false,
      data: stageData,
    },
  ];
  useEffect(() => {
    const getActualData = async () => {
      if (productData && !productData.cache) {
        const info = await getAttributesApi({
          url: optionsTag["products"].get.url,
          request: optionsTag["products"].get.request,
          filter: `/${productData.id}?page=1&pageSize=500`,
        });
        if (info) {
          dispatch(
            setItemsAttribute({
              itemId: productData.id,
              attrName: "products",
              attrValues: info,
            })
          );
        }
      }
    };
    getActualData();
  }, []);

  useEffect(() => {
    dispatch(setActiveProductId(productData.id))
    return () => {
      dispatch(setActiveProductId(undefined))
    }
  }, [])

  useEffect(() => {
    if (productData) {
      const result: any = [];
      productData.availableOptions.map((item: any) => {
        result.push({
          ...item,
          typeValue: "material",
        });
      });
      productData.availableGeometryOptions.map((item: any) => {
        result.push({
          ...item,
          typeValue: "geometry",
        });
      });
      setAllOptionsProductData(result);
    }
  }, [productData]);

  if (!productData) {
    setEditAttr(false);
  }
  return (
    <>
      {productData && (
        <div className={s.edit}>
          <div className={s.head}>
            <div className={s.back} onClick={() => setEditAttr(false)}>
              <BackArrow />
              Back to products list
            </div>
            <div className={s.content}>
              <div className={s.name}>{productData.name}</div>
              <div className={s.id}>
                <IdPlace id={productData.id} />
              </div>
              <div className={s.toggle}>
                <ToggleBtn
                  eneble={productData.enabled}
                  setEneble={() => {
                    toggleEnableAttribute({
                      id: productData.id,
                      name: productData.name,
                      mount: productData.mountPoint,
                      nameAttr: "products",
                      enabled: !productData.enabled,
                      dispatch,
                    });
                  }}
                />
              </div>
            </div>
            {!activeModal && (
              <div className={s.add}>
                <ButtonWrapper
                  text="Connect"
                  clickEvent={(type: string) => {
                    const data = type === "options" ? optionData : stageData;
                    const activeData =
                      type === "options"
                        ? productData.availableOptions
                        : productData.availableStages;
                    const list: any = [];
                    const listName: any = [];
                    activeData.map((i: any) => {
                      const id = uuidv4();
                      if (type === "options") {
                        listName.push({
                          id,
                          optionId: i.id,
                          label: i.name,
                          disabled: true,
                          proxyName: i.proxyName,
                        });
                      } else {
                        listName.push(i.id);
                      }
                    });

                    data.map((i: any) => {
                      list.push({
                        value: i.id,
                        label: i.name,
                        type: i.typeValue,
                      });
                    });

                    dispatch(
                      changeShowModal({
                        nameModal: "addProductItem",
                        stateModal: true,
                        otherParams: {
                          attrId: productData.id,
                          listName: listName,
                          list,
                          type,
                        },
                      })
                    );
                  }}
                  type="outlane"
                  icon={true}
                  selectList={selectList}
                />
              </div>
            )}
          </div>
          <div className={s.renderWRapper}>
            <RenderProduct
              id={productData.id}
              activeModal={activeModal}
              setActiveModal={setActiveModal}
            />
            <AllRenders
              id={productData.id}
              activeModal={activeModal}
              setActiveModal={setActiveModal}
            />
          </div>
          <div className={s.wrap}>
            <CombiningOptions
              id={productData.id}
              activeModal={activeModal}
              setActiveModal={setActiveModal}
            />
            {!activeModal && <CategoryProduct productData={productData} />}
          </div>

          {!activeModal && (
            <div className={s.items}>
              <div className={`${s.options} customScroll`}>
                {allOptionsProductData.length >= 1 ? (
                  <>
                    {allOptionsProductData.map((item: any, index: number) => {
                      const keyName: any =
                        item.typeValue === "material"
                          ? "options"
                          : "geometryOptions";
                      const keyId: any =
                        item.typeValue === "material"
                          ? "optionId"
                          : "geometryOptionId";
                      return (
                        <ItemStandart
                          key={`item_variant_attr_${index}`}
                          eneble={item.enabled}
                          setEneble={async () => {
                            await toggleEnableAttributeOption({
                              id: item[keyId],
                              mount: item.mountPoint,
                              name: item.name,
                              apiName: keyName,
                              nameAttr: "options",
                              enabled: !item.enabled,
                              dispatch,
                            });
                            loadItemsProductInState({ dispatch });
                          }}
                          title={`${item.proxyName}`}
                          subtitle={item.name}
                          deleteEvent={async () => {
                            const keyName: any =
                              item.typeValue === "material"
                                ? "options"
                                : "geometryOptions";
                            const keyId: any =
                              item.typeValue === "material"
                                ? "optionId"
                                : "geometryOptionId";
                            await changeAttributeApi({
                              values: {
                                [keyName]: [item.id],
                              },
                              urlParam: `${productData.id}/${keyName}`,
                              typeSend: "json",
                              url: optionsTag["products"].delete.url,
                              request: optionsTag["products"].delete.request,
                            });
                            loadItemProductInState({
                              dispatch,
                              productId: productData.id,
                            });
                          }}
                          dropdown={true}
                          child={() => {
                            return (
                              <div className={s.test}>
                                {item.variants.map(
                                  (itemAttr: any, index: number) => {
                                    return (
                                      <ItemStandart
                                        key={`itemAttr_${itemAttr.id}_option_attr_${index}`}
                                        eneble={itemAttr.enabled}
                                        img={itemAttr.image}
                                        id={itemAttr.id}
                                        setEneble={async () => {
                                          const keyParentName: any =
                                            item.typeValue === "material"
                                              ? "options"
                                              : "geometryOptions";
                                          const keyName: any =
                                            item.typeValue === "material"
                                              ? "variants"
                                              : "geometryVariants";
                                          console.log("item: ", item);

                                          if (itemAttr.enabled) {
                                            await changeAttributeApi({
                                              values: {
                                                [keyName]: [itemAttr.id],
                                              },
                                              urlParam: `${productData.id}/${keyParentName}/${item.id}/${keyName}`,
                                              typeSend: "json",
                                              url: optionsTag["products"].delete
                                                .url,
                                              request:
                                                optionsTag["products"].delete
                                                  .request,
                                            });
                                          } else {
                                            await changeAttributeApi({
                                              values: {
                                                [keyName]: [itemAttr.id],
                                              },
                                              urlParam: `${productData.id}/${keyParentName}/${item.id}/${keyName}`,
                                              typeSend: "json",
                                              url: optionsTag["products"].create
                                                .url,
                                              request:
                                                optionsTag["products"].create
                                                  .request,
                                            });
                                          }

                                          loadItemProductInState({
                                            dispatch,
                                            productId: productData.id,
                                          });
                                        }}
                                        title={itemAttr.name}
                                        isMini={true}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            );
                          }}
                        />
                      );
                    })}
                  </>
                ) : (
                  <NoneData text="/// No options connected ///" />
                )}
              </div>
              <div className={`${s.stages} customScroll`}>
                <div className={s.titleAttr}>Stages</div>
                {productData.availableStages.length >= 1 ? (
                  <>
                    {productData.availableStages.map(
                      (item: any, index: number) => {
                        return (
                          <ItemStandart
                            key={`item_camera_attr_${index}`}
                            eneble={item.enabled}
                            setEneble={async () => {
                              await toggleEnableAttribute({
                                id: item.id,
                                name: item.name,
                                nameAttr: "stages",
                                enabled: !item.enabled,
                                dispatch,
                              });
                              loadItemsProductInState({ dispatch });
                            }}
                            title={item.name}
                            deleteEvent={async () => {
                              await changeAttributeApi({
                                values: {
                                  stages: [item.id],
                                },
                                urlParam: `${productData.id}/stages`,
                                typeSend: "json",
                                url: optionsTag["products"].delete.url,
                                request: optionsTag["products"].delete.request,
                              });
                              loadItemProductInState({
                                dispatch,
                                productId: productData.id,
                              });
                            }}
                            dropdown={true}
                            child={() => {
                              return (
                                <div className={s.test}>
                                  {item.cameras.map(
                                    (itemAttr: any, index: number) => {
                                      return (
                                        <ItemStandart
                                          key={`itemAttr_${itemAttr.id}_cameras_attr_${index}`}
                                          eneble={itemAttr.enabled}
                                          img={itemAttr.image}
                                          id={itemAttr.id}
                                          setEneble={async () => {
                                            if (itemAttr.enabled) {
                                              await changeAttributeApi({
                                                values: {
                                                  cameras: [itemAttr.id],
                                                },
                                                urlParam: `${productData.id}/cameras`,
                                                typeSend: "json",
                                                url: optionsTag["products"]
                                                  .delete.url,
                                                request:
                                                  optionsTag["products"].delete
                                                    .request,
                                              });
                                            } else {
                                              await changeAttributeApi({
                                                values: {
                                                  cameras: [itemAttr.id],
                                                },
                                                urlParam: `${productData.id}/cameras`,
                                                typeSend: "json",
                                                url: optionsTag["products"]
                                                  .create.url,
                                                request:
                                                  optionsTag["products"].create
                                                    .request,
                                              });
                                            }

                                            loadItemProductInState({
                                              dispatch,
                                              productId: productData.id,
                                            });
                                          }}
                                          title={itemAttr.name}
                                          isMini={true}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }}
                          />
                        );
                      }
                    )}
                  </>
                ) : (
                  <NoneData text="/// No stages connected ///" />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
