// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollectionEditAttr_head__sIqXP {
  padding: 16px 24px;
  border-bottom: 1px solid #e3e5fa;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.CollectionEditAttr_back__PoyS\\+ {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #6c757d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}
.CollectionEditAttr_back__PoyS\\+ svg {
  margin-right: 4px;
}
.CollectionEditAttr_back__PoyS\\+ svg path {
  transition: all 0.2s;
}
.CollectionEditAttr_back__PoyS\\+:hover {
  color: #37CC8F;
}
.CollectionEditAttr_back__PoyS\\+:hover svg path {
  fill: #37CC8F;
}

.CollectionEditAttr_content__Fat0W {
  display: flex;
  align-items: center;
}

.CollectionEditAttr_name__Wkkrh {
  color: #343a40;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 24px;
}

.CollectionEditAttr_items__dLI4U {
  padding: 12px 24px;
  height: calc(100vh - 210px);
}`, "",{"version":3,"sources":["webpack://./src/widgets/CollectionEditAttr/CollectionEditAttr.module.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;AAFF;;AAIA;EACE,oBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AADF;AAGE;EACE,iBAAA;AADJ;AAEI;EACE,oBAAA;AAAN;AAIE;EACE,cAAA;AAFJ;AAKM;EACE,aAAA;AAHR;;AASA;EACE,aAAA;EACA,mBAAA;AANF;;AAQA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AALF;;AAQA;EACE,kBAAA;EACA,2BAAA;AALF","sourcesContent":[".edit {\n}\n\n.head {\n  padding: 16px 24px;\n  border-bottom: 1px solid #e3e5fa;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  justify-content: space-between;\n}\n.back {\n  transition: all .2s;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  color: #6c757d;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 200% */\n\n  svg {\n    margin-right: 4px;\n    path {\n      transition: all .2s;\n    }\n  }\n\n  &:hover {\n    color: #37CC8F;\n\n    svg {\n      path {\n        fill: #37CC8F;\n      }\n    }\n  }\n}\n\n.content {\n  display: flex;\n  align-items: center;\n}\n.name {\n  color: #343a40;\n  text-align: center;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  margin-right: 24px;\n}\n\n.items {\n  padding: 12px 24px;\n  height: calc(100vh - 210px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `CollectionEditAttr_head__sIqXP`,
	"back": `CollectionEditAttr_back__PoyS+`,
	"content": `CollectionEditAttr_content__Fat0W`,
	"name": `CollectionEditAttr_name__Wkkrh`,
	"items": `CollectionEditAttr_items__dLI4U`
};
export default ___CSS_LOADER_EXPORT___;
