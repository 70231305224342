// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalAddEnvironmentVariant_wrapper__0-d9T {
  position: fixed;
  width: 100%;
  max-width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);
}

.ModalAddEnvironmentVariant_head__gftWK {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
}
.ModalAddEnvironmentVariant_head__gftWK span {
  color: #343A40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 15.4px */
}

.ModalAddEnvironmentVariant_content__-VxHo {
  padding: 0px 12px;
  padding-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/processes/modals/ModalAddEnvironmentVariant/ModalAddEnvironmentVariant.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mDAAA;AACF;;AAKA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gCAAA;EACA,mBAAA;AAFF;AAGE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AADJ;;AAKA;EACE,iBAAA;EACA,oBAAA;AAFF","sourcesContent":[".wrapper {\n  position: fixed;\n  width: 100%;\n  max-width: 580px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-sizing: border-box;\n  height: auto;\n  border-radius: 10px;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);\n}\n\n\n\n\n.head {\n  padding: 12px 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid #dfdfdf;\n  margin-bottom: 20px;\n  span {\n    color: #343A40;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 110%; /* 15.4px */\n  }\n}\n\n.content {\n  padding: 0px 12px;\n  padding-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ModalAddEnvironmentVariant_wrapper__0-d9T`,
	"head": `ModalAddEnvironmentVariant_head__gftWK`,
	"content": `ModalAddEnvironmentVariant_content__-VxHo`
};
export default ___CSS_LOADER_EXPORT___;
