import { useSortable } from "@dnd-kit/sortable";
import { DragIcon } from "../../../../../../assets/svg/DragIcon";
import s from './RowDragHandleCell.module.scss'

export const RowDragHandleButton = ({ id }: { id: number }) => {
  const { attributes, listeners } = useSortable({
    id,
  });
  return (
    <button className={s.drag} {...attributes} {...listeners}>
      <DragIcon />
    </button>
  );
};