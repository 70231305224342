// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/FuturaPT-Book.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/FuturaPT-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/FuturaPT-Heavy.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/FuturaPT-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "FuturaPT";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "FuturaPT";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "FuturaPT";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: "FuturaPT";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/utils/styles/fonts.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4DAAuD;EACvD,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,4DAAyD;EACzD,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,4DAAwD;EACxD,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,4DAAuD;EACvD,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: \"FuturaPT\";\n  src: url(\"./fonts/FuturaPT-Book.woff2\") format(\"woff2\");\n  font-weight: 400;\n}\n@font-face {\n  font-family: \"FuturaPT\";\n  src: url(\"./fonts/FuturaPT-Medium.woff2\") format(\"woff2\");\n  font-weight: 500;\n}\n@font-face {\n  font-family: \"FuturaPT\";\n  src: url(\"./fonts/FuturaPT-Heavy.woff2\") format(\"woff2\");\n  font-weight: 600;\n}\n@font-face {\n  font-family: \"FuturaPT\";\n  src: url(\"./fonts/FuturaPT-Bold.woff2\") format(\"woff2\");\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
