// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoodrinateCameraComponent_wrapper__LaAf5 {
  display: flex;
  align-items: center;
}

.CoodrinateCameraComponent_coord__Y73pd:first-child {
  margin-right: 20px;
}
.CoodrinateCameraComponent_coordTitle__Hthmi {
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  margin-bottom: 6px;
}
.CoodrinateCameraComponent_coordTitle__Hthmi .CoodrinateCameraComponent_req__mDDhb {
  position: relative;
  top: 0;
  right: -4px;
  color: #ff4b4b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.CoodrinateCameraComponent_coordWrapper__kWWP- {
  display: flex;
  align-items: center;
}

.CoodrinateCameraComponent_singleCoord__Xnf08 .CoodrinateCameraComponent_rotation__rCzcD {
  width: 130px;
}

.CoodrinateCameraComponent_position__saVDK,
.CoodrinateCameraComponent_rotation__rCzcD {
  width: 62px;
  margin-right: 2px;
}
.CoodrinateCameraComponent_position__saVDK input,
.CoodrinateCameraComponent_rotation__rCzcD input {
  padding: 20px 7px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/processes/modals/ModalAddCamera/components/CoodrinateCameraComponent/CoodrinateCameraComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAEE;EACE,kBAAA;AACJ;AACE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,kBAAA;AACJ;AAAI;EACE,kBAAA;EACA,MAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEN;AACE;EACE,aAAA;EACA,mBAAA;AACJ;;AAGE;EACE,YAAA;AAAJ;;AAIA;;EAEE,WAAA;EACA,iBAAA;AADF;AAGE;;EACE,iBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n}\n.coord {\n  &:first-child {\n    margin-right: 20px;\n  }\n  &Title {\n    color: #343a40;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 24px; /* 171.429% */\n    margin-bottom: 6px;\n    .req {\n      position: relative;\n      top: 0;\n      right: -4px;\n      color: #ff4b4b;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 24px;\n    }\n  }\n  &Wrapper {\n    display: flex;\n    align-items: center;\n  }\n}\n.singleCoord {\n  .rotation {\n    width: 130px;\n  }\n}\n\n.position,\n.rotation {\n  width: 62px;\n  margin-right: 2px;\n\n  input {\n    padding: 20px 7px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CoodrinateCameraComponent_wrapper__LaAf5`,
	"coord": `CoodrinateCameraComponent_coord__Y73pd`,
	"coordTitle": `CoodrinateCameraComponent_coordTitle__Hthmi`,
	"req": `CoodrinateCameraComponent_req__mDDhb`,
	"coordWrapper": `CoodrinateCameraComponent_coordWrapper__kWWP-`,
	"singleCoord": `CoodrinateCameraComponent_singleCoord__Xnf08`,
	"rotation": `CoodrinateCameraComponent_rotation__rCzcD`,
	"position": `CoodrinateCameraComponent_position__saVDK`
};
export default ___CSS_LOADER_EXPORT___;
