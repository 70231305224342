import { IProjectSettings } from "./settings";

export type NameModals =
  | "loader"
  | "addProject"
  | "deleteProject"
  | "addOption"
  | "addVariant"
  | "addStage"
  | "addCamera"
  | "addCollection"
  | "addCollectionItem"
  | "addProduct"
  | "addProductItem"
  | "addEnvironment"
  | "addEnvironmentVariant"
  | "addCategory"
  | "createRender";
export type ModalStatusT = {
  isShow: boolean;
  otherParams?: any;
};
export type modalsListT = Record<NameModals, ModalStatusT>;

export type projectsListT = {
  id: number;
  name: string;
  attributesList: attributesListT;
  organizationId?: number;
};

export type attributesItemsT = {
  id: number;
  name: string;
  cache: boolean;
  enabled: boolean;
  [key: string]: any;
};
export type rowsT = {
  id: number;
  name: string;
  enabled: boolean;
  items: attributesItemsT[];
};
export type attributesValuesT = {
  count: number;
  cache: boolean;
  rows: rowsT[];
};
export type attributeItemsNamesT = "variants" | "cameras" | "categories" | "environmentVariants";

export type attributesNamesT =
  | "options"
  | "stages"
  | "collections"
  | "environment"
  | "products";
export type attributesListT = Record<attributesNamesT, attributesValuesT>;

type activeProjectT = undefined | number;
export type initialUiStateT = {
  activeProject: activeProjectT;
  activeProductId: number;
  activeTab: string | undefined;
  organizationId: number;
  renderJobs: any;
  projectsList: projectsListT[];
  categories: any[];
  modalsList: modalsListT;
  organizationList: IOrganization[];
};

export type stateT = {
  ui: initialUiStateT;
  settings: IProjectSettings;
};

export const defaultAttributes: attributesListT = {
  options: {
    count: 0,
    cache: false,
    rows: [],
  },
  stages: {
    count: 0,
    cache: false,
    rows: [],
  },
  collections: {
    count: 0,
    cache: false,
    rows: [],
  },
  environment: {
    count: 0,
    cache: false,
    rows: [],
  },
  products: {
    count: 0,
    cache: false,
    rows: [],
  },
};

export interface IOrganization {
  id: number
  name: string
  slug: string
  users: User[]
}

export interface User {
  id: number
  username: string
}