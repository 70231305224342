import { combineReducers } from "redux";
import ui from "./ui";
import { settings } from "./settings";

const rootReducer = combineReducers({
  ui: ui,
  settings: settings,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
