// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalFrame_modal_bg__g3Ag3 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(23, 23, 46, 0.6);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/processes/modals/ModalFrame/ModalFrame.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,iCAAA;EACA,kCAAA;UAAA,0BAAA;EACA,YAAA;EACA,aAAA;AAAJ","sourcesContent":[".modal {\n  &_bg {\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 999;\n    background: rgba(23, 23, 46, 0.60);\n    backdrop-filter: blur(4px);\n    width: 100vw;\n    height: 100vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_bg": `ModalFrame_modal_bg__g3Ag3`
};
export default ___CSS_LOADER_EXPORT___;
