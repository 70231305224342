import { apiRoutesValueT } from "../constants";

export type enironmentOptionRoutesNameT =
  | "GET_ENVIRONMENT_OPTIONS"
  | "UPDATE_ENVIRONMENT_OPTION";
type enironmentOptionRoutesT = Record<
  enironmentOptionRoutesNameT,
  apiRoutesValueT
>;

const API = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const enironmentOptionRoutes: enironmentOptionRoutesT = {
  GET_ENVIRONMENT_OPTIONS: {
    url: `${API}/environmentOptions`,
    request: "get",
  },
  UPDATE_ENVIRONMENT_OPTION: {
    url: `${API}/environmentOptions`,
    request: "put",
  },
};
