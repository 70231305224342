import { useEffect } from "react";
import axiosInstance from "../../processes/api/instance";
import { popUp } from "../../features/popUp/PopUp";

export const statusMessages: Record<number, { consoleMessage: string; popupMessage: string }> = {
  400: {
    consoleMessage: 'Bad Request: The request could not be understood by the server.',
    popupMessage: 'Error 400: Bad Request.',
  },
  401: {
    consoleMessage: 'Unauthorized: You must be authenticated.',
    popupMessage: 'Error 401: Unauthorized. Please log in.',
  },
  403: {
    consoleMessage: 'Forbidden: You do not have permission to access this resource.',
    popupMessage: 'Error 403: Forbidden.',
  },
  404: {
    consoleMessage: 'Not Found: The requested resource could not be found.',
    popupMessage: 'Error 404: Not Found.',
  },
  500: {
    consoleMessage: 'Internal Server Error: Something went wrong on the server.',
    popupMessage: 'Error 500: Internal Server Error. Please try again later.',
  },
  502: {
    consoleMessage: 'Bad Gateway: The server received an invalid response.',
    popupMessage: 'Error 502: Bad Gateway.',
  },
  503: {
    consoleMessage: 'Service Unavailable: The server is temporarily unavailable.',
    popupMessage: 'Error 503: Service Unavailable.',
  },
};

export const useAxiosErrorHandling = () => {
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (config) => config,
      async (error) => {
        if (error.response) {
          const { status, data } = error.response;
          const statusInfo = statusMessages[status];

          if (statusInfo) {
            console.error(statusInfo.consoleMessage, data?.message || '');
            popUp({ type: "failure", message: statusInfo.popupMessage });
          } else {
            console.error('HTTP Error:', status, data?.message || 'An unexpected error occurred.');
            popUp({
              type: "failure",
              message: `Error ${status}: ${data?.message || 'An unexpected error occurred.'}`,
            });
          }
        } else if (error.request) {
          console.error('No Response:', 'The server did not respond to the request.');
          popUp({
            type: "failure",
            message: 'No Response: Unable to connect to the server. Please check your internet connection.',
          });
        } else {
          console.error('Request Error:', error.message);
          popUp({ type: "failure", message: `Request Error: ${error.message}` });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [axiosInstance]);
};
