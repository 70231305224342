export interface ITabsSettingData {
  id: number;
  name: string;
}

export const MOCK_ATTR_DATA: ITabsSettingData[] = [
  {
    id: 1,
    name: "image",
  },
  {
    id: 2,
    name: "webGl",
  },
  {
    id: 3,
    name: "stream",
  },
  {
    id: 4,
    name: "apps",
  },
];

export const MOCK_FINAL_IMAGE_RESOLUTION_VALUE = [
  { id: 1, name: "25", value: 25 },
  { id: 2, name: "50", value: 50 },
  { id: 3, name: "75", value: 75 },
  { id: 4, name: "100", value: 100 },
];

export const MOCK_SELECT_STREAM_VALUE = [
  { id: 0, name: "Low", value: 0 },
  { id: 1, name: "Medium", value: 1 },
  { id: 2, name: "High", value: 2 },
  { id: 3, name: "Epic", value: 3 },
  { id: 4, name: "Cinematic", value: 4 },
];

export const MOCK_CUSTOM_VALUE = [
  { id: 1, name: "Default", value: -1 },
  { id: 1, name: "Custom", value: 0 },
];

export const REQUIRED_BUILD_ERROR_MESSAGE =
  'Build is required when Select build is "Custom".';

export const ROTATION_TYPE = [
  { id: 1, name: "Disabled", value: 0 },
  { id: 2, name: "Camera", value: 1 },
  { id: 3, name: "Object", value: 2 },
];

export const BACKGROUND_TYPE = [
  {
    id: 1,
    name: "Scene",
    value: 0,
  },
  {
    id: 2,
    name: "Transparent",
    value: 1,
  },
  {
    id: 3,
    name: "SolidColor",
    value: 2,
  },
];

export const ANTI_ALIASING = [
  { id: 0, name: "No AA", value: 0 },
  { id: 1, name: "FXAA", value: 1 },
  { id: 2, name: "TAA", value: 2 },
  { id: 3, name: "TSR", value: 3 },
];
