import { useDispatch, useSelector } from "react-redux";
import { Close } from "../../assets/svg/Close";
import s from "./HeadProjectInfo.module.scss";
import { FC } from "react";
import {
  getActiveProject,
  getProjectById,
} from "../../redux/selectors/selectors";
import { projectsListT } from "../../redux/reducers/types";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constants";
import { setActiveProject } from "../../redux/actions";
import { IdPlace } from "../../shared/IdPlace/IdPlace";
import { LinkPlace } from "../../shared/LinkPlace/LinkPlace";

export const HeadProjectInfo: FC = () => {
  const id = useSelector(getActiveProject);
  const project: projectsListT = useSelector(getProjectById(Number(id)));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={s.wrapper}>
      <div className={s.info}>
        <button
          className={s.close}
          onClick={() => {
            navigate(PAGES.PROJECTS);
            dispatch(setActiveProject({ id: undefined }));
          }}
        >
          <Close />
        </button>
        <div className={s.title}>{project && project.name}</div>
      </div>

      <div className={s.tools}>
        <LinkPlace id={project && project.id} />
        <IdPlace id={project && project.id} />
      </div>
    </div>
  );
};
