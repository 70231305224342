import { FC } from "react";
import s from "./OptionEditAttr.module.scss";
import { BackArrow } from "../../assets/svg/BackArrow";
import { ToggleBtn } from "../../shared/ToggleBtn/ToggleBtn";
import { useDispatch, useSelector } from "react-redux";
import { ButtonWrapper } from "../../shared/ButtonWrapper/ButtonWrapper";
import { getItemAttributeById } from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";

import {
  handleAddItemAttribute,
  handleDeleteItemAttribute,
  handleDuplicateItemAttribute,
  handleEditAttribute,
  handleEnableAttribute,
  handleEnableItemAttribute,
} from "./functions/optionEditAttrEvents";
import { useUpdateActualData } from "./hooks/useUpdateActualData";

type OptionEditAttrT = {
  setEditAttr: any;
  editAttr: any;
};

export const OptionEditAttr: FC<OptionEditAttrT> = ({ ...props }) => {
  const { setEditAttr, editAttr } = props;
  const dispatch = useDispatch();
  const optionData = useSelector(
    getItemAttributeById({
      name: "options",
      id: editAttr.id,
      typeValue: editAttr.typeValue,
    })
  );
  useUpdateActualData({ optionData, dispatch });
  if (!optionData) {
    setEditAttr(false);
  }

  return (
    <>
      {optionData && (
        <div className={s.edit}>
          <div className={s.head}>
            <div className={s.back} onClick={() => setEditAttr(false)}>
              <BackArrow />
              Back to options list
            </div>
            <div className={s.content}>
              <div className={s.name}>{optionData.name}</div>
              <div className={s.toggle}>
                <ToggleBtn
                  eneble={optionData.enabled}
                  setEneble={async () =>
                    handleEnableAttribute({ optionData, dispatch })
                  }
                />
              </div>
            </div>
            <div className={s.add}>
              <ButtonWrapper
                text="Add new variant"
                clickEvent={() =>
                  handleAddItemAttribute({ optionData, dispatch })
                }
                type="outlane"
                icon={true}
              />
            </div>
          </div>
          <div className={s.items}>
            {optionData.items.length >= 1 ? (
              <>
                {optionData.items.map((item: any, index: number) => {
                  console.log("item1: ", item);
                  return (
                    <ItemStandart
                      key={`item_attr_${index}`}
                      eneble={item.enabled}
                      img={item.image}
                      id={item.id}
                      setEneble={async () =>
                        handleEnableItemAttribute({
                          item,
                          optionData,
                          dispatch,
                        })
                      }
                      title={item.name}
                      deleteEvent={async () =>
                        handleDeleteItemAttribute({
                          item,
                          optionData,
                          dispatch,
                        })
                      }
                      duplicateEvent={async () =>
                        handleDuplicateItemAttribute({
                          item,
                          optionData,
                          dispatch,
                        })
                      }
                      editEvent={() =>
                        handleEditAttribute({ item, optionData, dispatch })
                      }
                    />
                  );
                })}
              </>
            ) : (
              <NoneData text="/// No variants available yet ///" />
            )}
          </div>
        </div>
      )}
    </>
  );
};
