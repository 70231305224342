// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToggleBtn_toggle__hJCdP {
  transition: all 0.2s;
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background: #e0e2e6;
  cursor: pointer;
}
.ToggleBtn_toggle__hJCdP.ToggleBtn_active__er1zR {
  background: #31b27e;
}
.ToggleBtn_toggle__hJCdP.ToggleBtn_active__er1zR .ToggleBtn_point__WhJeH {
  left: calc(100% - 20px - 2px);
}

.ToggleBtn_wrapper__ebiES {
  display: flex;
  align-items: center;
}
.ToggleBtn_wrapper__ebiES.ToggleBtn_hideText__\\+YQf0 .ToggleBtn_text__g7zJD {
  display: none;
}

.ToggleBtn_text__g7zJD {
  width: 62px;
  margin-right: 8px;
  color: #040503;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ToggleBtn_point__WhJeH {
  transition: all 0.2s;
  background: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/shared/ToggleBtn/ToggleBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AACF;AAAE;EACE,mBAAA;AAEJ;AADI;EACE,6BAAA;AAGN;;AACA;EACE,aAAA;EACA,mBAAA;AAEF;AAAI;EACE,aAAA;AAEN;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AACA;EACE,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAEF","sourcesContent":[".toggle {\n  transition: all 0.2s;\n  position: relative;\n  width: 44px;\n  height: 24px;\n  border-radius: 12px;\n  background: #e0e2e6;\n  cursor: pointer;\n  &.active {\n    background: #31b27e;\n    .point {\n      left: calc(100% - 20px - 2px);\n    }\n  }\n}\n.wrapper {\n  display: flex;\n  align-items: center;\n  &.hideText {\n    .text {\n      display: none;\n    }\n  }\n}\n.text {\n  width: 62px;\n  margin-right: 8px;\n  color: #040503;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.point {\n  transition: all 0.2s;\n  background: #fff;\n  position: absolute;\n  top: 2px;\n  left: 2px;\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `ToggleBtn_toggle__hJCdP`,
	"active": `ToggleBtn_active__er1zR`,
	"point": `ToggleBtn_point__WhJeH`,
	"wrapper": `ToggleBtn_wrapper__ebiES`,
	"hideText": `ToggleBtn_hideText__+YQf0`,
	"text": `ToggleBtn_text__g7zJD`
};
export default ___CSS_LOADER_EXPORT___;
