import styles from './PasteButton.module.scss';
import { PastIcon } from '../../../../../assets/svg/PastIcon';
import { popUp } from '../../../../../features/popUp/PopUp';

interface IPasteButton {
  fieldName: string,
  onChange(name: string, value: string): void
}

export const PasteButton = ({fieldName, onChange}: IPasteButton) => {

 const handlePaste =  async () => {
    try {
      const clipboardData = await navigator.clipboard.readText();

      const xyzRegex = /X=([-.\d]+),Y=([-.\d]+),Z=([-.\d]+)/;
      const pyrRegex = /Pitch=([-.\d]+),Yaw=([-.\d]+),Roll=([-.\d]+)/;
      
        if (xyzRegex.test(clipboardData)) {
            const [, x, y, z] = clipboardData.match(xyzRegex)!;

            onChange(`${fieldName}.x`, x);
            onChange(`${fieldName}.y`, y);
            onChange(`${fieldName}.z`, z);

        } else if (pyrRegex.test(clipboardData)) {
            const [, pitch, yaw, roll] = clipboardData.match(pyrRegex)!;

            onChange(`${fieldName}.pitch`, pitch);
            onChange(`${fieldName}.yaw`, yaw);
            onChange(`${fieldName}.roll`, roll);
        } else {
          popUp({ type: "failure", message: "Invalid format. Please copy either XYZ or Pitch/Yaw/Roll data" });
        }
    } catch (err) {
      popUp({ type: "failure", message: "Failed to read clipboard data" });
    }
  }

  return (
    <button className={styles.paste} type="button" onClick={handlePaste}>
      <PastIcon />
      <span>Paste</span>
    </button>
  )
}
