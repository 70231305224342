import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalDeleteProject.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import {
  addNewProject,
  changeShowModal,
  setAllProjects,
  updateProject,
} from "../../../redux/actions";
import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";

type ModalDeleteProjectT = {
  closeFunc: any;
  data?: any;
};
export const ModalDeleteProject: FC<ModalDeleteProjectT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  // Получаем первый элемент массива (часть строки до первого "/")
  const idPage = parts[1];
  const { deleteEvent } = data;
  const text = "Delete project";
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function deleteProject() {
    deleteEvent(idPage);
    closeFunc();
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>{text}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <div className={s.title}>
          Are you sure you want to delete the project with all its models and
          options?
        </div>
        <div className={s.btns}>
          <div className={s.cancel}>
            <ButtonWrapper
              clickEvent={closeFunc}
              text="Cancel"
              type="transparent"
            />
          </div>
          <div className={s.delete}>
            <ButtonWrapper
              clickEvent={deleteProject}
              text="Delete Project"
              type="warning"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
