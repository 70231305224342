import { apiRoutesValueT } from "../constants";
export type variantsRoutesNameT =
  | "GET_VARIANTS"
  | "UPDATE_VARIANT"
  | "CREATE_VARIANT"
  | "DELETE_VARIANT"
  | "RETRIEVE_VARIANT"
  | "GET_GEOMETRY_VARIANTS"
  | "UPDATE_GEOMETRY_VARIANT"
  | "CREATE_GEOMETRY_VARIANT"
  | "DELETE_GEOMETRY_VARIANT"
  | "RETRIEVE_GEOMETRY_VARIANT";
type variantsRoutesT = Record<variantsRoutesNameT, apiRoutesValueT>;

const API = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const variantsRoutes: variantsRoutesT = {
  GET_VARIANTS: {
    url: `${API}/variants`,
    request: "get",
  },
  CREATE_VARIANT: {
    url: `${API}/variants`,
    request: "post",
  },
  UPDATE_VARIANT: {
    url: `${API}/variants`,
    request: "put",
  },
  DELETE_VARIANT: {
    url: `${API}/variants`,
    request: "delete",
  },
  RETRIEVE_VARIANT: {
    url: `${API}/variants`,
    request: "get",
  },

  GET_GEOMETRY_VARIANTS: {
    url: `${API}/geometryVariants`,
    request: "get",
  },
  CREATE_GEOMETRY_VARIANT: {
    url: `${API}/geometryVariants`,
    request: "post",
  },
  UPDATE_GEOMETRY_VARIANT: {
    url: `${API}/geometryVariants`,
    request: "put",
  },
  DELETE_GEOMETRY_VARIANT: {
    url: `${API}/geometryVariants`,
    request: "delete",
  },
  RETRIEVE_GEOMETRY_VARIANT: {
    url: `${API}/geometryVariants`,
    request: "get",
  },
};
