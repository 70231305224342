import { FC, useId, useState, useEffect } from "react";
import s from "./ChooseFileWrapper.module.scss";
import { Download } from "../../assets/svg/Download";
import { FileIcon } from "../../assets/svg/FileIcon";
import { Delete } from "../../assets/svg/Delete";
import clsx from "clsx";

type ChooseFileWrapperT = {
  name: string;
  setValue: any;
  value: any;
  isRequired?: boolean;
  placeholder: string;
  addClassName?: any;
  accept: string;
  linkImage?: string;
  textPreview?: string;
  disabled?: boolean;
};

function formatBytes(bytes: any) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (bytes < kilobyte) {
    return bytes + " B";
  } else if (bytes < megabyte) {
    return (bytes / kilobyte).toFixed(2) + " KB";
  } else {
    return (bytes / megabyte).toFixed(2) + " MB";
  }
}

export const ChooseFileWrapper: FC<ChooseFileWrapperT> = ({ ...props }) => {
  const {
    isRequired = false,
    name,
    setValue,
    value,
    placeholder,
    addClassName = false,
    accept,
    linkImage,
    textPreview,
    disabled,
  } = props;
  let classWrapper = `${s.wrapper}`;
  addClassName
    ? (classWrapper += ` ${addClassName}`)
    : (classWrapper = `${s.wrapper}`);
  const inputId = useId();
  const [text, setText]: any = useState();

  useEffect(() => {
    if (linkImage) {
      const loadPrevImg = async () => {
        // const file = await loadImageFromURL(linkImage);

        const parts = linkImage.split("/");
        const filename = parts[parts.length - 1];
        setValue(filename);
      };
      loadPrevImg();
    }
    if (textPreview) {
      const loadPrevImg = async () => {
        setText("zip already downloaded");
      };
      loadPrevImg();
    }
  }, []);

  return (
    <div className={classWrapper}>
      <label htmlFor={inputId}>
        {name}
        {isRequired && <span className={s.req}>*</span>}
      </label>
        <label htmlFor={inputId} className={clsx({[s.place]: true}, {[s.disabled]: disabled})} >
        <input
          type={"file"}
          onChange={(event: any) => {
            setValue(event.target.files[0]);
          }}
          id={inputId}
          placeholder={placeholder}
          accept={accept}
          disabled={disabled}
        />
        <Download />
        <div className={s.title}>
          Drag & Drop or <span className={s.blue}>Choose file</span> to upload
        </div>
        <div className={s.placeholder}>{placeholder}</div>
      </label>
      {value ? (
        <div className={s.file}>
          <div className={s.fileContainer}>
            <FileIcon />
            {typeof value === "string" ? (
              <div className={s.info}>
                <div className={s.name}>{value}</div>
              </div>
            ) : (
              <div className={s.info}>
                <div className={s.name}>{value.name}</div>
                <div className={s.size}>{formatBytes(value.size)}</div>
              </div>
            )}
          </div>

          <div className={s.close} onClick={() => setValue()}>
            <Delete />
          </div>
        </div>
      ) : (
        <span className={s.text}>{text}</span>
      )}
    </div>
  );
};
