import { useNavigate, useParams } from 'react-router-dom';
import { SettingIcon } from '../../../assets/svg/SettingIcon'
import style from "./SettingsButton.module.scss"
import { PAGES_SETTINGS_ROUTES } from '../../../utils/constants';

interface ISettingsButton {
  projectId: number;
}

export const SettingsButton = ({projectId}: ISettingsButton) => {
  const navigate = useNavigate();
  const params = useParams();

  const handleButtonClick = (e: any) => {
    const currrentSettingTabs = params["*"] || PAGES_SETTINGS_ROUTES.IMAGE;
    e.stopPropagation();
    navigate(`/${projectId}/settings/${currrentSettingTabs}`);
  }

  return (
    <button className={style.button} onClick={(e) => handleButtonClick(e)}>
      <SettingIcon />
      <span>Settings</span>
    </button>
  )
}
