// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginWidget_wrapper__yn6FS {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoginWidget_logo__Qmp7J {
  margin-bottom: 36px;
}

.LoginWidget_content__ZxVlh {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 30px 24px;
}

.LoginWidget_title__UjfFC {
  color: #343A40;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/LoginWidget/LoginWidget.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AACA;EACE,mBAAA;AAEF;;AAAA;EACI,WAAA;EACF,mBAAA;EACA,gBAAA;EACA,kBAAA;AAGF;;AADA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,mBAAA;AAIF","sourcesContent":[".wrapper {\n    width: 450px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.logo {\n  margin-bottom: 36px;\n}\n.content {\n    width: 100%;\n  border-radius: 10px;\n  background: #fff;\n  padding: 30px 24px;\n}\n.title {\n  color: #343A40;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 24px; /* 150% */\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LoginWidget_wrapper__yn6FS`,
	"logo": `LoginWidget_logo__Qmp7J`,
	"content": `LoginWidget_content__ZxVlh`,
	"title": `LoginWidget_title__UjfFC`
};
export default ___CSS_LOADER_EXPORT___;
