interface InnerDataObject {
  id: number;
  type: string;
  name: string;
  isRepeat: boolean;
}

export interface OuterDataObject {
  id: number;
  data: InnerDataObject[];
  optionId: number;
  layerOrder: number;
  [key: string]: any; // Для других свойств, которые могут быть в объекте
}

export function markDuplicates(
  dataArray: OuterDataObject[]
): OuterDataObject[] {
  // Используем Set для отслеживания уникальных комбинаций id и type
  const uniqueSet = new Set<string>();

  // Проходимся по каждому элементу массива
  dataArray.forEach((outerObj) => {
    outerObj.data.forEach((innerObj) => {
      const key = `${innerObj.id}-${innerObj.type}`;
      if (uniqueSet.has(key)) {
        innerObj.isRepeat = true;
      } else {
        uniqueSet.add(key);
        innerObj.isRepeat = false;
      }
    });
  });

  // Удаляем объекты с пустым массивом в параметре data
  const filteredArray = dataArray.filter((obj) => obj.data.length > 0);

  // Обновляем индекс всех оставшихся объектов
  filteredArray.forEach((obj, idx) => {
    obj.index = idx + 1;
  });

  return filteredArray;
}
