// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1024px) {
  .Index_wrapper__yGzVJ {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Index/Index.module.scss"],"names":[],"mappings":"AAQA;EACI;IACE,aAAA;EAPJ;AACF","sourcesContent":["\n\n\n\n\n\n\n\n@media (max-width: 1024px) {\n    .wrapper {\n      display: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Index_wrapper__yGzVJ`
};
export default ___CSS_LOADER_EXPORT___;
