import React, { useState } from 'react'
import styles from "./DropDownCameraSettings.module.scss"
import { ArrowBottom } from '../../assets/svg/ArrowBottom';
import clsx from 'clsx';
import { PastIcon } from '../../assets/svg/PastIcon';
import { CopyIcon } from '../../assets/svg/CopyIcon';
import { popUp } from '../../features/popUp/PopUp';

interface IDropDownCameraSettings {
  children: React.ReactNode;
  headerTitle: string;
  name: string;
  values: any;
  handleChangeValue(key: string, value: any): void;
}

export const DropDownCameraSettings = ({headerTitle, name, values, handleChangeValue, children}: IDropDownCameraSettings) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const getNestedValue = (path: string, obj: any): any =>
    path.split(".").reduce((acc, key) => (acc ? acc[key] : undefined), obj);
  

  const extractDropdownValues = (dropdownName: string) => {
    const dropdownElement = document.querySelector(`[data-dropdown="${dropdownName}"]`);
    if (!dropdownElement) return {};

    const inputs = dropdownElement.querySelectorAll("input");
    const buttons = dropdownElement.querySelectorAll("button");
    const extractedValues: Record<string, any> = {};
    
    inputs.forEach((input) => {
      if (input.name) {
        if(values.hasOwnProperty(input.name)){
          extractedValues[input.name] = getNestedValue(input.name, values);

        } else {
          const propertyName = input.name.split('.')[0];
          const propertyKey = input.name.split('.')[1];

          extractedValues[propertyName] = {...extractedValues[propertyName], [propertyKey]: getNestedValue(input.name, values)}
        }
      }
    });

    buttons.forEach((button) => {
      if (button.name) {
        if(values.hasOwnProperty(button.name)){
          extractedValues[button.name] = getNestedValue(button.name, values);

        } else {
          const propertyName = button.name.split('.')[0];
          const propertyKey = button.name.split('.')[1];

          extractedValues[propertyName] = {...extractedValues[propertyName], [propertyKey]: getNestedValue(button.name, values)}
        }
      }
    });

    return extractedValues;
  };

  const handleSetOpenProps = () => {
    setIsOpen(!isOpen);
  }

  const handleCopy = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    try {
      const copiedData = JSON.stringify({ name, data: extractDropdownValues(name) });
      await navigator.clipboard.writeText(copiedData);
      popUp({type: 'success', message: `Settings from ${headerTitle} copied!`})
    } catch (err) {
      console.error("Copy failed:", err);
    }
  }

  const handlePaste = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    try {
      if (!document.hasFocus()) {
        popUp({type: 'failure', message: "Please click anywhere on the page and try pasting again."})
        return;
      }
  
      const clipboardData = await navigator.clipboard.readText();
      const { name: copiedName, data: pastedData } = JSON.parse(clipboardData);

      if (copiedName !== name) {
        popUp({type: 'failure', message: `Error: Cannot paste. Copied settings belong to ${copiedName}.`})
        return;
      }

      Object.entries(pastedData).forEach(([key, value]) => {
        handleChangeValue(key, value);
      });
      popUp({type: 'success', message: `Settings pasted into ${headerTitle}!`})
    } catch (err) {
      console.error("Paste failed:", err);
      popUp({type: 'failure', message: "Error: Invalid data!"})
    }
  }

  return (
    <>
    <button className={styles.wrapper} onClick={() => handleSetOpenProps()} type="button" >
      <div className={styles.header} >
        {headerTitle}
      <div className={styles.copyPaste}>
        <div className={styles.manipulation}>
          <button type="button" onClick={(e) => handleCopy(e)}><CopyIcon /> Copy</button>
          <button type="button" onClick={(e) => handlePaste(e)}><PastIcon />Paste</button>
        </div>
        <span 
          className={clsx({[styles.arrow]: isOpen === false}, {[styles['arrow-active']]: isOpen === true})}
          >
          <ArrowBottom />
        </span>
      </div>
      </div>
    </button>

    {isOpen ? (
      <div className={styles.body} data-dropdown={name}>
        {children}
      </div>
      ) : null}
    </>
  )
}
