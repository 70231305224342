import * as Yup from "yup";

const isCorrectNumber = /^[-]?\d*\.?\d+$/;
const FIELD_VALIDATION_MESSAGE =
  "Value must be a valid number (can be negative, float, or decimal)";

export const cameraSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Name must be at least 1 character long")
    .max(128, "Name cannot exceed 128 characters")
    .required("Name is a required field"),
  position: Yup.object({
    x: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Position x is required"),
    y: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Position y is required"),
    z: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Position z is required"),
  }).required("Rotation is a required field"),
  rotation: Yup.object({
    roll: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Rotation roll is required"),
    pitch: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Rotation pitch is required"),
    yaw: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Rotation yaw is required"),
  }).required("Rotation is a required field"),
  productRotation: Yup.object({
    roll: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Product rotation roll is required"),
    pitch: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Product rotation pitch is required"),
    yaw: Yup.string()
      .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
      .required("Product rotation yaw is required"),
  }).required("Product rotation is a required field"),
  rotationAngle: Yup.string()
    .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
    .transform((value) => Number(value))
    .test("min", "Rotation angle must be at least -360", (value) => {
      if (value) {
        return Number(value) >= -360;
      }
    })
    .test("max", "Rotation angle cannot exceed 360", (value) => {
      if (value) {
        return Number(value) <= 360;
      }
    })
    .transform((value) => String(value)),
  fovCorrection: Yup.string()
    .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
    .transform((value) => Number(value))
    .test("min", "FOV correction must be at least 0", (value) => {
      if (value) {
        return Number(value) >= 0;
      }
    })
    .test("max", "correction cannot exceed 1", (value) => {
      if (value) {
        return Number(value) <= 1;
      }
    })
    .transform((value) => String(value)),
  fov: Yup.string()
    .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
    .transform((value) => Number(value))
    .test("min", "FOV must be at least 5", (value) => {
      if (value) {
        return Number(value) >= 5;
      }
    })
    .test("max", "FOV cannot exceed 170", (value) => {
      if (value) {
        return Number(value) <= 170;
      }
    })
    .transform((value) => String(value))
    .required("FOV is a required field"),
  rotationType: Yup.number().required("Rotation type is a required field"),
  rotationSteps: Yup.number().min(1, "rotationSteps must be at least 1"),
  nearClipPlane: Yup.string()
    .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
    .transform((value) => Number(value))
    .test("min", "Near clip plane must be at least 0", (value) => {
      if (value) {
        return Number(value) >= 0;
      }
    })
    .transform((value) => String(value))
    .required("Near clip plane is a required field"),
  exposure: Yup.string()
    .nullable()
    .test(
      "is-valid-range",
      "Value must be a number between -15 and 15 or null",
      (value) => {
        if (value === null) return true;
        const num = parseFloat(value as string);
        return !isNaN(num) && num >= -15 && num <= 15;
      }
    ),
  backgroundType: Yup.number().required("Background type is a required field"),
  renderDimensions: Yup.boolean(),
  backgroundColor: Yup.string().when("backgroundType", {
    is: 2,
    then: (schema) => schema.required("Background color is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  lineOffset: Yup.string()
    .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
    .transform((value) => Number(value))
    .test("min", "Line offset must be at least 0", (value) => {
      if (value) {
        return Number(value) >= 0;
      }
    })
    .test("max", "Line offset cannot exceed 3", (value) => {
      if (value) {
        return Number(value) <= 3;
      }
    })
    .transform((value) => String(value))
    .when("renderDimensions", {
      is: true,
      then: (schema) => schema.required("Line offset is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  lineWidth: Yup.string()
    .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
    .transform((value) => Number(value))
    .test("min", "Line width must be at least 1", (value) => {
      if (value) {
        return Number(value) >= 1;
      }
    })
    .transform((value) => String(value))
    .when("renderDimensions", {
      is: true,
      then: (schema) => schema.required("Line width is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  capsLength: Yup.string()
    .matches(isCorrectNumber, FIELD_VALIDATION_MESSAGE)
    .transform((value) => Number(value))
    .test("min", "Caps length must be at least 0", (value) => {
      if (value) {
        return Number(value) >= 0;
      }
    })
    .transform((value) => String(value))
    .when("renderDimensions", {
      is: true,
      then: (schema) => schema.required("Caps length is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  fontSize: Yup.number().min(1, "Font size must be at least 1"),
  fontName: Yup.string(),
  renderWidth: Yup.number()
    .min(320, "Render image width must be at least 320")
    .max(7680, "Render image width must be less or equal 7680"),
  renderHeight: Yup.number()
    .min(320, "Render image height must be at least 320")
    .max(7680, "Render image height must be less or equal 7680"),
  imageWidth: Yup.number()
    .min(320, "Final image width must be at least 320")
    .max(7680, "Final image width must be less or equal 7680"),
  imageHeight: Yup.number()
    .min(320, "Final image height must be at least 320")
    .max(7680, "Final image height must be less or equal 7680"),
});
