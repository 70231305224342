// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsButton_button__Pul5E {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  margin-left: 12px;
  background-color: transparent;
  color: #6C757D;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.SettingsButton_button__Pul5E:hover {
  color: #37cc8f;
}
.SettingsButton_button__Pul5E:hover svg {
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.SettingsButton_button__Pul5E:hover svg path {
  fill: #37cc8f;
}
.SettingsButton_button__Pul5E svg {
  width: 16px;
  height: 16px;
  fill: #6C757D;
}
.SettingsButton_button__Pul5E svg path {
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.SettingsButton_button__Pul5E span {
  margin-left: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/settings/SettingButton/SettingsButton.module.scss","webpack://./src/utils/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,6BAAA;EACA,cCLmB;EDMnB,YAAA;EACA,eAAA;EACA,oBAAA;EACA,4BAAA;EACA,yBAAA;EACA,wBAAA;EACA,uBAAA;AADF;AAGE;EACE,cClBM;ADiBV;AAGI;EACE,oBAAA;EACA,4BAAA;EACA,yBAAA;EACA,wBAAA;EACA,uBAAA;AADN;AAEM;EACE,aC3BE;AD2BV;AAKE;EACE,WAAA;EACA,YAAA;EACA,aChCiB;AD6BrB;AAKI;EACE,oBAAA;EACA,4BAAA;EACA,yBAAA;EACA,wBAAA;EACA,uBAAA;AAHN;AAOE;EACE,gBAAA;AALJ","sourcesContent":["@import '../../../utils/styles/colors.scss';\n\n.button {\n  display: flex;\n  align-items: center;\n  font-size: 12px;\n  font-weight: 400 ;\n  margin-left: 12px;\n  background-color: transparent;\n  color: $content-light-dark;\n  border: none;\n  cursor: pointer;\n  transition: all .4s;\n  -webkit-transition: all .4s;\n  -moz-transition: all .4s;\n  -ms-transition: all .4s;\n  -o-transition: all .4s;\n\n  &:hover {\n    color: $primary;\n\n    svg {\n      transition: all .4s;\n      -webkit-transition: all .4s;\n      -moz-transition: all .4s;\n      -ms-transition: all .4s;\n      -o-transition: all .4s;\n      path {\n        fill: $primary;\n      }\n    }\n  }\n\n  svg {\n    width: 16px;\n    height: 16px;\n    fill: $content-light-dark;\n\n    path {\n      transition: all .4s;\n      -webkit-transition: all .4s;\n      -moz-transition: all .4s;\n      -ms-transition: all .4s;\n      -o-transition: all .4s;\n    }\n  }\n\n  span {\n    margin-left: 2px;\n  }\n}","$white: #ffffff;\n$primary: #37cc8f;\n$backgorund-color: #f6f7fd;\n$main-text-color: #343a40;\n$content-light-dark: #6C757D;\n$border-color: #e8e8ef;\n$backgorund-button-color: #E3E5FA;\n$warning-color: #FF4B4B;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `SettingsButton_button__Pul5E`
};
export default ___CSS_LOADER_EXPORT___;
