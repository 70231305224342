import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Collections } from '../pages/Collections/Index'
import { Environment } from '../pages/Environment/Index'
import { App } from '../pages/Index/Index'
import { Login } from '../pages/Login/Index'
import { Options } from '../pages/Options/Index'
import { Products } from '../pages/Products/Index'
import { Stages } from '../pages/Stages/Index'
import { PAGES, PAGES_SETTINGS_ROUTES } from '../utils/constants'

const Settingslayout = lazy(() => import("../components/settings/Settingslayout/Settingslayout"));
const ImageSettings = lazy(() => import("../components/settings/Image/ImageSettings"));
const WebGl = lazy(() => import("../components/settings/WebGl/WebGl"));
const Stream = lazy(() => import("../components/settings/Stream/Stream"));
const Apps = lazy(() => import("../components/settings/Apps/Apps"));

export const MainRouter = () => {
  return (
    <Suspense fallback={<p>...loading</p>}>
      <BrowserRouter>
        <Routes>
          <Route path={PAGES.PROJECTS} element={<App />} />
          <Route path={PAGES.LOGIN} element={<Login />} />
          <Route path={`/:id${PAGES.OPTIONS}`} element={<Options />} />
          <Route path={`/:id${PAGES.STAGES}`} element={<Stages />} />
          <Route path={`/:id${PAGES.COLLECTIONS}`} element={<Collections />} />
          <Route path={`/:id${PAGES.ENVIRONMENT}`} element={<Environment />} />
          <Route path={`/:id${PAGES.PRODUCTS}`} element={<Products />} />
          <Route path=":id/settings/*" element={<Settingslayout/>}>
            <Route index path={PAGES_SETTINGS_ROUTES.IMAGE} element={<ImageSettings />}/>
            <Route path={PAGES_SETTINGS_ROUTES.WEBGL} element={<WebGl />}/>
            <Route path={PAGES_SETTINGS_ROUTES.STREAM} element={<Stream />}/>
            <Route path={PAGES_SETTINGS_ROUTES.APPS} element={<Apps />}/>
            <Route path="*" element={<p>Page was not found</p>} />
          </Route>
          <Route path="*" element={<p>Page was not found</p>} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}
