// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Index_wrapper__Fq0bW {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

@media (max-width: 1024px) {
  .Index_wrapper__Fq0bW {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  width: 100vw;\n  height: 100vh;\n}\n\n@media (max-width: 1024px) {\n  .wrapper {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Index_wrapper__Fq0bW`
};
export default ___CSS_LOADER_EXPORT___;
