// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IdPlace_id__eiEL6 {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 8px;
  background: #e3e5fa;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.IdPlace_id__eiEL6 svg {
  margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/shared/IdPlace/IdPlace.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EAEA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AAAF;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".id {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  padding: 2px 8px;\n  border-radius: 8px;\n  background: #e3e5fa;\n\n  color: #343a40;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 171.429% */\n\n  svg {\n    margin-left: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"id": `IdPlace_id__eiEL6`
};
export default ___CSS_LOADER_EXPORT___;
