import { apiRoutesValueT } from "../constants";

export type camerasRoutesNameT =
  | "GET_CAMERAS"
  | "UPDATE_CAMERA"
  | "CREATE_CAMERA"
  | "DELETE_CAMERA"
  | "RETRIEVE_CAMERA";
type camerasRoutesT = Record<camerasRoutesNameT, apiRoutesValueT>;

const API = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const camerasRoutes: camerasRoutesT = {
  GET_CAMERAS: {
    url: `${API}/cameras`,
    request: "get",
  },
  CREATE_CAMERA: {
    url: `${API}/cameras`,
    request: "post",
  },
  UPDATE_CAMERA: {
    url: `${API}/cameras`,
    request: "put",
  },
  DELETE_CAMERA: {
    url: `${API}/cameras`,
    request: "delete",
  },
  RETRIEVE_CAMERA: {
    url: `${API}/cameras`,
    request: "get",
  },
};
