// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalDeleteProject_wrapper__sBLs4 {
  position: fixed;
  width: 100%;
  max-width: 420px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);
}

.ModalDeleteProject_head__-mE2o {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
}
.ModalDeleteProject_head__-mE2o span {
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 15.4px */
}

.ModalDeleteProject_content__zLMA5 {
  padding: 0px 12px;
  padding-bottom: 16px;
}

.ModalDeleteProject_title__vVLpe {
  margin-top: 12px;
  margin-bottom: 20px;
  color: #343a40;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ModalDeleteProject_btns__64zwL {
  display: flex;
  align-items: center;
}
.ModalDeleteProject_btns__64zwL .ModalDeleteProject_cancel__IGyA0 {
  width: 48%;
  margin-right: 12px;
}
.ModalDeleteProject_btns__64zwL .ModalDeleteProject_delete__smxXm {
  width: 48%;
}`, "",{"version":3,"sources":["webpack://./src/processes/modals/ModalDeleteProject/ModalDeleteProject.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mDAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gCAAA;EACA,mBAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAEJ;;AAEA;EACE,iBAAA;EACA,oBAAA;AACF;;AACA;EACE,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;AAEF;AAAE;EACE,UAAA;EACA,kBAAA;AAEJ;AAAE;EACE,UAAA;AAEJ","sourcesContent":[".wrapper {\n  position: fixed;\n  width: 100%;\n  max-width: 420px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-sizing: border-box;\n  height: auto;\n  border-radius: 10px;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);\n}\n\n.head {\n  padding: 12px 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid #dfdfdf;\n  margin-bottom: 20px;\n  span {\n    color: #343a40;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 110%; /* 15.4px */\n  }\n}\n\n.content {\n  padding: 0px 12px;\n  padding-bottom: 16px;\n}\n.title {\n  margin-top: 12px;\n  margin-bottom: 20px;\n  color: #343a40;\n  text-align: center;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n\n.btns {\n  display: flex;\n  align-items: center;\n\n  .cancel {\n    width: 48%;\n    margin-right: 12px;\n  }\n  .delete {\n    width: 48%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ModalDeleteProject_wrapper__sBLs4`,
	"head": `ModalDeleteProject_head__-mE2o`,
	"content": `ModalDeleteProject_content__zLMA5`,
	"title": `ModalDeleteProject_title__vVLpe`,
	"btns": `ModalDeleteProject_btns__64zwL`,
	"cancel": `ModalDeleteProject_cancel__IGyA0`,
	"delete": `ModalDeleteProject_delete__smxXm`
};
export default ___CSS_LOADER_EXPORT___;
