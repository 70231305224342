import { FC, useId } from "react";
import s from "./CheckboxWrapper.module.scss";
import { Active } from "../../assets/svg/Active";
import clsx from 'clsx';

type CheckboxWrapperT = {
  name?: string;
  setValue: any;
  value: any;
  addClassName?: any;
  disabled?: boolean;
  title?: string;
  isCameraSetting?: boolean
};
export const CheckboxWrapper: FC<CheckboxWrapperT> = ({ ...props }) => {
  const { name, setValue, value, disabled, title, addClassName = false, isCameraSetting = false } = props;

  return (
    <button
      className={clsx({
        [s.wrapper]: true,
        [addClassName]: addClassName,
        [s.disabled]: disabled,
        [s.active]: value,
      })}
      onClick={() => {
        if(isCameraSetting) {
          setValue(name, !value);
        } else {
          setValue(!value);
        }
      }}
      disabled={disabled}
      type="button"
    >
      <div className={s.checkbox}>
        <Active />
      </div>
      <div className={s.name}>{title || name}</div>
    </button>
  );
};
