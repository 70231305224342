import { apiRoutesValueT } from "../constants";

export type stagesRoutesNameT =
  | "GET_STAGES"
  | "UPDATE_STAGE"
  | "CREATE_STAGE"
  | "DELETE_STAGE"
  | "RETRIEVE_STAGE";
type stagesRoutesT = Record<stagesRoutesNameT, apiRoutesValueT>;

const API = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const stagesRoutes: stagesRoutesT = {
  GET_STAGES: {
    url: `${API}/stages`,
    request: "get",
  },
  CREATE_STAGE: {
    url: `${API}/stages`,
    request: "post",
  },
  UPDATE_STAGE: {
    url: `${API}/stages`,
    request: "put",
  },
  DELETE_STAGE: {
    url: `${API}/stages`,
    request: "delete",
  },
  RETRIEVE_STAGE: {
    url: `${API}/stages`,
    request: "get",
  },
};
