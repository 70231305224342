import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import s from "./CombinationList.module.scss";
import { Plus } from "../../../../../../assets/svg/Plus";
import { CloseSecond } from "../../../../../../assets/svg/CloseSecond";
import { getUniqueObjects } from "../../functions/getUniqueObjects";
import { markDuplicates } from "../../functions/markDuplicates";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { RowDragHandleButton } from "../RowDragHandleButton/RowDragHandleCell";
import { IOriginalArray } from "../../CombiningOptions";

type CombinationListT = {
  option: any;
  listOptions: any;
  data: any;
  id: number;
  index?: number;
  setListOptions: (arg: IOriginalArray[]) => void;
  setIsDisabledDataButton: (arg: boolean) => void;
};

export const CombinationList: FC<CombinationListT> = ({
  data,
  listOptions,
  option,
  id,
  index,
  setListOptions,
  setIsDisabledDataButton,
}) => {
  const wrapperRef: any = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [contentItems, setContentItems] = useState([]);

  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.4 : 1,
    zIndex: isDragging ? 1 : 0,
    border: isDragging ? "1px dashed rgba(171, 173, 200, 1)": "none",
    backgroundColor: isDragging ? "rgba(246, 247, 253, 1)": "transparent"
  };

  useEffect(() => {
    const uniqueObjects: any = getUniqueObjects(option.data, data);
    setContentItems(uniqueObjects);
  }, [option]);

  function closeFunc() {
    setIsOpen(false);
  }

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  // function updateApi(values: any) {
  //   if (values.length >= 1) {
  //     const dep: any = [];
  //     let optionId: any;
  //     let type;
  //     values.map((val: any, index: number) => {
  //       if (index === 0) {
  //         optionId = val.id;
  //         type = val.type === "material" ? "options" : "geometryOptions";
  //       } else {
  //         dep.push({
  //           id: val.id,
  //           type: val.type,
  //         });
  //       }
  //     });
  //   }
  // }

  return (
    <div className={s.item}
      ref={setNodeRef}
      style={style}
    >
      <RowDragHandleButton id={id} />
      <div className={s.index}>{(index && index >= 0) ? `${index}.` : null}</div>
      <div className={s.lists}>
        {option.data.map((list: any, index: number) => {
          return (
            <div
              className={`${s.list} ${list.isRepeat && s.repeat}`}
              key={`list${option.index}_${list.name}_${index}`}
              onClick={() => {
                const updatedList: any = [];
                listOptions.map((listOption: any) => {
                  if (listOption.id === option.id) {
                    const newArr: any = [];
                    listOption.data.map((val: any) => {
                      if (val.id === list.id && val.type === list.type) {
                      } else {
                        newArr.push(val);
                      }
                    });
                    updatedList.push({
                      ...listOption,
                      data: newArr,
                    });
                  } else {
                    updatedList.push(listOption);
                  }
                });

                const updatedDataArray: any = markDuplicates(updatedList);
                setIsDisabledDataButton(false);
                setListOptions(updatedDataArray);
              }}
            >
              <span>{list.name}</span>
              <CloseSecond />
            </div>
          );
        })}
        <div className={`${s.add} ${isOpen && s.active}`} ref={wrapperRef}>
          <div className={s.head} onClick={() => setIsOpen(!isOpen)}>
            <Plus />
            <span>Add combination</span>
          </div>
          <div className={s.content}>
            {contentItems.map((item: any, i: number) => {
              return (
                <div
                  key={`item_list_list${option.index}_${i}${item.name}`}
                  className={s.contentItem}
                  onClick={() => {
                    const updatedList: any = [];

                    listOptions.map((l: any) => {
                      if (l.id === option.id) {
                        const newData = l.data;
                        newData.push(item);
                        updatedList.push({
                          ...l,
                          optionId: l.optionId,
                          layerOrder: l.layerOrder,
                          data: newData,
                        });
                      } else {
                        updatedList.push(l);
                      }
                    });

                    const updatedDataArray = markDuplicates(updatedList);
                    setListOptions(updatedDataArray);
                    setIsDisabledDataButton(false);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
