// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileHint_wrapper__fVxmW {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: 0px 4%;
}

.MobileHint_title__Rus81 {
  color: #343A40;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
  margin-top: 20px;
  margin-bottom: 16px;
}

.MobileHint_subtitle__Zb4M7 {
  color: #343A40;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
}

@media (max-width: 1024px) {
  .MobileHint_wrapper__fVxmW {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/MobileHint/MobileHint.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;AACF;;AACA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAGF;;AADA;EACE;IACE,aAAA;EAIF;AACF","sourcesContent":[".wrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 999;\n  display: none;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: calc(100%);\n  height: 100vh;\n  background: #fff;\n  padding: 0px 4%;\n}\n.title {\n  color: #343A40;\n  text-align: center;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 110%; /* 26.4px */\n  margin-top: 20px;\n  margin-bottom: 16px;\n}\n.subtitle {\n  color: #343A40;\n  text-align: center;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%; /* 23.4px */\n}\n@media (max-width: 1024px) {\n  .wrapper {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MobileHint_wrapper__fVxmW`,
	"title": `MobileHint_title__Rus81`,
	"subtitle": `MobileHint_subtitle__Zb4M7`
};
export default ___CSS_LOADER_EXPORT___;
