import { apiRoutesValueT } from "../constants";

export type projectRoutesNameT =
  | "GET_PROJECTS"
  | "UPDATE_PROJECT"
  | "CREATE_PROJECT"
  | "DELETE_PROJECT"
  | "RETRIEVE_PROJECT";
type projectRoutesT = Record<projectRoutesNameT, apiRoutesValueT>;

const API = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const projectRoutes: projectRoutesT = {
  GET_PROJECTS: {
    url: `${API}/projects`,
    request: "get",
  },
  CREATE_PROJECT: {
    url: `${API}/projects`,
    request: "post",
  },
  UPDATE_PROJECT: {
    url: `${API}/projects`,
    request: "put",
  },
  DELETE_PROJECT: {
    url: `${API}/projects`,
    request: "delete",
  },
  RETRIEVE_PROJECT: {
    url: `${API}/projects`,
    request: "get",
  },
};

