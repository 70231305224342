// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductWidget_wrapper__29Fpp {
  position: relative;
  height: calc(100vh - 210px);
  padding: 0px 24px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ProductWidget/ProductWidget.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,2BAAA;EACA,iBAAA;EACA,gBAAA;AACJ","sourcesContent":[".wrapper {\n    position: relative;\n    height: calc(100vh - 210px);\n    padding: 0px 24px;\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ProductWidget_wrapper__29Fpp`
};
export default ___CSS_LOADER_EXPORT___;
