// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalAddCategory_wrapper__gxpwv {
  position: fixed;
  width: 100%;
  max-width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);
}

.ModalAddCategory_head__ftsU\\+ {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
}
.ModalAddCategory_head__ftsU\\+ span {
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 15.4px */
}

.ModalAddCategory_content__fP3tO {
  padding: 0px 12px;
  padding-bottom: 16px;
}

.ModalAddCategory_hint__au8PV {
  position: relative;
  top: -23px;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 6px;
  display: flex;
  align-items: center;
}
.ModalAddCategory_hint__au8PV .ModalAddCategory_red__mV9aw {
  color: #ff4b4b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/processes/modals/ModalAddCategory/ModalAddCategory.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mDAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gCAAA;EACA,mBAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAEJ;;AAEA;EACE,iBAAA;EACA,oBAAA;AACF;;AACA;EACE,kBAAA;EACA,UAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AAEF;AADE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,iBAAA;AAGJ","sourcesContent":[".wrapper {\n  position: fixed;\n  width: 100%;\n  max-width: 580px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-sizing: border-box;\n  height: auto;\n  border-radius: 10px;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);\n}\n\n.head {\n  padding: 12px 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid #dfdfdf;\n  margin-bottom: 20px;\n  span {\n    color: #343a40;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 110%; /* 15.4px */\n  }\n}\n\n.content {\n  padding: 0px 12px;\n  padding-bottom: 16px;\n}\n.hint {\n  position: relative;\n  top: -23px;\n  color: #343a40;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%;\n  margin-top: 6px;\n  display: flex;\n  align-items: center;\n  .red {\n    color: #ff4b4b;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 130%; /* 18.2px */\n    margin-right: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ModalAddCategory_wrapper__gxpwv`,
	"head": `ModalAddCategory_head__ftsU+`,
	"content": `ModalAddCategory_content__fP3tO`,
	"hint": `ModalAddCategory_hint__au8PV`,
	"red": `ModalAddCategory_red__mV9aw`
};
export default ___CSS_LOADER_EXPORT___;
