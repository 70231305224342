import { useEffect, useMemo, useState } from "react";
import { ArrowLink } from "../../../../assets/svg/ArrowLink";
import { CloseSecond } from "../../../../assets/svg/CloseSecond";
import s from "./CombiningOptions.module.scss";
import { CombinationList } from "./components/CombinationList/CombinationList";
import { optionsTag } from "../../../../utils/constants";
import getAttributesApi from "../../../../processes/api/attributes/getAttributesApi";
import { markDuplicates } from "./functions/markDuplicates";
import { getUniqueObjects } from "./functions/getUniqueObjects";
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { updateCompbiningOptionAPI } from "../../../../processes/api/products/updateCombiningOptionAPI";
import { useSelector } from "react-redux";
import { getActiveProductId } from "../../../../redux/selectors/selectors";
import { SortableOverlay } from "./components/SortableOverlay/SortableOverlay";

interface IDataItem {
  id:number, name:string, type: string, isRepeat: boolean,
}

export interface IOriginalArray {
  id: number;
  layerOrder: number;
  optionId: number;
  index?: number;
  data: IDataItem[];
}

export const CombiningOptions = ({ activeModal, setActiveModal, id }: any) => {
  const isActive = activeModal === "CombiningOptions";
  const [data, setData] = useState([]);
  const [listOptions, setListOptions] = useState<IOriginalArray[]>([]);
  const [allOptionsProductData, setAllOptionsProductData]: any = useState([]);
  const [unusedData, setUnusedData]: any = useState([]);
  const [isDisabledDataButton, setIsDisabledDataButton] = useState<boolean>(true);
  const activeProductId = useSelector(getActiveProductId());
  const [active, setActive] = useState<IOriginalArray | null>(null);
  const activeItem = useMemo(
    () => listOptions.find((item) => item.id === active?.id),
    [active, listOptions]
  );

  const listIds = useMemo(
    () => listOptions?.map(({ id }) => id),
    [listOptions]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const allList: any = [];

    listOptions.map((l: any) => {
      allList.push(...l.data);
    });

    const uniqueObjects: any = getUniqueObjects(allList, data);
    setUnusedData(uniqueObjects);
  }, [listOptions]);

  useEffect(() => {
    async function getProduct() {
      const info = await getAttributesApi({
        url: optionsTag["products"].get.url,
        request: optionsTag["products"].get.request,
        filter: `/${id}?page=1&pageSize=500`,
      });
      const result: any = [];
      info.availableOptions.map((item: any) => {
        result.push({
          ...item,
          typeValue: "material",
        });
      });
      info.availableGeometryOptions.map((item: any) => {
        result.push({
          ...item,
          typeValue: "geometry",
        });
      });
      setAllOptionsProductData(result);
    }

    getProduct();
  }, []);

  useEffect(() => {
    if (allOptionsProductData) {
      const options: any = [];
      allOptionsProductData.map((option: any, index: number) => {
        const typeVar =
          option.typeValue === "material" ? "optionId" : "geometryOptionId";
        options.push({
          // id: option[typeVar],
          id: option.id,
          optionId: option.optionId,
          type: option.typeValue,
          name: option.proxyName ? option.proxyName : option.name,
          layerOrder: option.layerOrder,
          isRepeat: false,
        });
      });
      setData(options);
    }
  }, [allOptionsProductData]);

  useEffect(() => {
    const startList: any = [];
    data.map((option: any, index: number) => {
      const activeData = allOptionsProductData.find(
        (val: any) => val.id === option.id && val.typeValue === option.type
      );
      const newData = [option];

      if (activeData.dependencies.length >= 1) {
        activeData.dependencies.map((i: any) => {
          const activeList = data.find(
            (opt: any) => opt.id === i.id && opt.type === i.type
          );
          if (activeList) {
            newData.push(activeList);
          }
        });
      }
      startList.push({
        id: option.id,
        layerOrder: option.layerOrder,
        optionId: newData[0].optionId,
        data: newData,
      });
    });    
    const updatedDataArray: any = markDuplicates(startList);
    // @ts-ignore
    setListOptions(updatedDataArray.sort((a, b) => a.layerOrder - b.layerOrder));
  }, [data]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setListOptions((data) => {
        const oldIndex = listIds.indexOf(active.id as number);
        const newIndex = listIds.indexOf(over.id as number);

        return arrayMove(data, oldIndex, newIndex);
      });
      setIsDisabledDataButton(false);
    }
  }

  const handleSaveCombinedOption = async () => {
    const reorderedLayerOrder = listOptions.map(({ data, id, layerOrder, ...rest }, index) => {

    // Modified data for backend format
    const modifiedData = data.map((item) => {
      return {
        id: item.id,
        type: item.type
      }
    }).slice(1)

      return {
        dependencies: modifiedData,
        ...rest,
        id: id,
        layerOrder: index + 1,
    }});
    
    setIsDisabledDataButton(true);
    try{
      updateCompbiningOptionAPI(reorderedLayerOrder, activeProductId);
    } catch(e: any) {
      console.error(e.message)
    }
  }

  return (
    <div
      className={`${s.wrapper} ${activeModal !== false && s.hide} ${
        isActive && s.active
      }`}
    >
      <div className={s.head}>
        <div className={s.title}>
          {isActive ? (
            <>
              <p>
                Combining combining
              </p>
              <button className={s.saveButton} onClick={() => handleSaveCombinedOption()} disabled={isDisabledDataButton}>Save options</button>
            </>

        ) : "Options"}
        </div>
        <div className={s.wrap}>
          {!isActive && <div className={s.hint}>Combining options</div>}
          <div
            className={`${s.btn} ${isActive && s.close}`}
            onClick={() => {
              if (isActive) {
                setActiveModal(false);
              } else {
                setActiveModal("CombiningOptions");
              }
            }}
          >
            {isActive ? (
              <>
                <CloseSecond />
                <span>Close</span>
              </>
            ) : (
              <>
                <ArrowLink />
                <span>Open</span>
              </>
            )}
          </div>
        </div>
      </div>
      {isActive && (
        <div className={s.content}>
          <div className={`${s.items} customScroll`}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragStart={({ active }: { active: any }) => {
              setActive(active);
            }}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={listOptions}
              strategy={verticalListSortingStrategy}
            >
            {listOptions.map((list: any, index: number) => {
              return (
                <CombinationList
                  key={list.id}
                  data={data}
                  listOptions={listOptions}
                  option={list}
                  id={list.id}
                  index={index+1}
                  setListOptions={setListOptions}
                  setIsDisabledDataButton={setIsDisabledDataButton}
                />
              );
            })}
            </SortableContext>
            {activeItem ? (
              <SortableOverlay>
                <CombinationList
                  key={activeItem.id}
                  data={data}
                  listOptions={listOptions}
                  option={activeItem}
                  id={activeItem.id}
                  index={activeItem.index}
                  setListOptions={setListOptions}
                  setIsDisabledDataButton={setIsDisabledDataButton}
                />
              </SortableOverlay>
            ): null}
          </DndContext>
          </div>
          <div className={s.unused}>
            <div className={s.label}>Unused options</div>
            <div className={s.unusedItems}>
              {unusedData.map((item: any, index: number) => {
                return (
                  <div
                    className={s.item}
                    key={`unUsedItems_${index}`}
                    onClick={() => {
                      const originalData = listOptions
                      const updatedList = [
                        ...originalData,
                        {
                          id: originalData.length + 1,
                          data: [item],
                          optionId: item.optionId,
                          layerOrder: item.layerOrder,
                        },
                      ];
                    const updatedDataArray: any = markDuplicates(updatedList);
                    setListOptions(updatedDataArray);
                    }}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
