// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Index_wrapper__EpZBr {
  width: calc(100% - 430px);
}

@media (max-width: 1024px) {
  .Index_wrapper__EpZBr {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Products/Index.module.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;AAAF;;AAQA;EACI;IACE,aAAA;EALJ;AACF","sourcesContent":["\n.wrapper {\n  width: calc(100% - 430px);\n}\n\n\n\n\n\n\n@media (max-width: 1024px) {\n    .wrapper {\n      display: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Index_wrapper__EpZBr`
};
export default ___CSS_LOADER_EXPORT___;
