import Notiflix from "notiflix";
import s from "./PopUp.module.scss";
// import "notyf/notyf.min.css";
export type popUpT = {
  type: "warning" | "failure" | "success" | "info";
  message: string;
};

export const popUp = ({ ...props }: popUpT) => {
  const { type, message } = props;
  Notiflix.Report.init({
    backgroundColor: "#1C1C1C",
  });
  Notiflix.Notify[type](message, {
    className: "notiflixWrapper",
    position: "left-bottom",
    borderRadius: "8px",
    clickToClose: true,
    timeout: 4000,
  });
};
