import { FC, useEffect } from "react";
import s from "../../ProjectMenu.module.scss";
import {
  changeShowModal,
  setActiveProject,
} from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { NoneData } from "../../../../shared/NoneData/NoneData";
import { EventBtn } from "../../../../shared/EventBtn/EventBtn";
import { useNavigate } from "react-router-dom";
import {
  getActiveProject,
  getActiveTab,
  getAllProjects,
  getOrganizationId,
} from "../../../../redux/selectors/selectors";
import { PAGES } from "../../../../utils/constants";
import { projectsListT } from "../../../../redux/reducers/types";
import { SettingsButton } from "../../../../components/settings/SettingButton/SettingsButton";

const renderProjectList = (projectList: projectsListT[], organizationId: number): projectsListT[] => {
  if(organizationId){
    return projectList.filter((filteredProject) => filteredProject.organizationId === organizationId);
  } else {
    return projectList;
  }
}

export const ProjectList: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeProject = useSelector(getActiveProject);
  const activeTab = useSelector(getActiveTab);
  const projectList: any[] = useSelector(getAllProjects);
  const organizationId = useSelector(getOrganizationId());
  const filteredProjects = renderProjectList(projectList, organizationId as number);

  useEffect(() => {
    const filteredValueIncludesActive = filteredProjects.filter((item) => item.id === activeProject)

    if(filteredValueIncludesActive.length){
      dispatch(setActiveProject({id: filteredValueIncludesActive[0]?.id}));
    } else {
      dispatch(setActiveProject({id: filteredProjects[0]?.id}));
    }
  }, [organizationId, filteredProjects, projectList, activeProject])

  return (
    <div className={`${s.content} customScroll`}>
      {filteredProjects.length >= 1 ? (
        filteredProjects.map((item: any, index: number) => {
          return (
            <div
              className={`${s.item} ${activeProject === item.id && s.active}`}
              key={`project_${item.id}`}
              onClick={() => {
                //@ts-ignore
                window.loadCategories = false;
                const tab = activeTab ? `/${activeTab}` : PAGES.OPTIONS; 
                navigate(`/${item.id}${tab}`);
              }}
            >
              <div className={s.name}>{item.name}</div>
              <div className={s.btns}>
                <EventBtn
                  type="edit"
                  clickEvent={() =>
                    dispatch(
                      changeShowModal({
                        nameModal: "addProject",
                        stateModal: true,
                        otherParams: {
                          id: item.id,
                          name: item.name,
                        },
                      })
                    )
                  }
                />
                <SettingsButton projectId={item.id} />
              </div>
            </div>
          );
        })
      ) : (
        <NoneData text="/// No projects available yet ///" />
      )}
    </div>
  );
};
