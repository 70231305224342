import { apiRoutesValueT } from "../constants";

export type loginRoutesNameT = "LOGIN" | "REFRESH";
type loginRoutesT = Record<loginRoutesNameT, apiRoutesValueT>;

const API = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const loginRoutes: loginRoutesT = {
  LOGIN: {
    url: `${API}/login`,
    request: "post",
  },
  REFRESH: {
    url: `${API}/refresh`,
    request: "post",
  },
};

