// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkPlace_wrapper__JLc4w {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.LinkPlace_item__zDOI4 {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.LinkPlace_label__-AlEv {
  color: #2a8efa;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/shared/LinkPlace/LinkPlace.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AAEF;;AACA;EACE,cAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,iBAAA;AAEF","sourcesContent":[".wrapper {\n  margin-right: 20px;\n  display: flex;\n  align-items: center;\n}\n.item {\n  display: flex;\n  align-items: center;\n  margin-right: 12px;\n  \n}\n.label {\n  color: #2a8efa;\n  cursor: pointer;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 171.429% */\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LinkPlace_wrapper__JLc4w`,
	"item": `LinkPlace_item__zDOI4`,
	"label": `LinkPlace_label__-AlEv`
};
export default ___CSS_LOADER_EXPORT___;
