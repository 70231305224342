import { apiRoutesValueT } from "../constants";

export type categoriesRoutesNameT =
  | "GET_CATEGORIES"
  | "UPDATE_CATEGORY"
  | "CREATE_CATEGORY"
  | "DELETE_CATEGORY"
  | "RETRIEVE_CATEGORY";
type categoriesRoutesT = Record<categoriesRoutesNameT, apiRoutesValueT>;

const API = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const categoriesRoutes: categoriesRoutesT = {
  GET_CATEGORIES: {
    url: `${API}/categories`,
    request: "get",
  },
  CREATE_CATEGORY: {
    url: `${API}/categories`,
    request: "post",
  },
  UPDATE_CATEGORY: {
    url: `${API}/categories`,
    request: "put",
  },
  DELETE_CATEGORY: {
    url: `${API}/categories`,
    request: "delete",
  },
  RETRIEVE_CATEGORY: {
    url: `${API}/categories`,
    request: "get",
  },
};
