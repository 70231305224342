// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputWrapper_wrapper__LGsKe {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.InputWrapper_wrapper__LGsKe label {
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  margin-bottom: 6px;
}
.InputWrapper_wrapper__LGsKe .InputWrapper_req__kO8v5 {
  position: relative;
  top: 0;
  right: -4px;
  color: #ff4b4b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.InputWrapper_wrapper__LGsKe input {
  outline: none;
  padding: 18px 20px;
  border-radius: 8px;
  border: 1px solid #e8e8ef;
  background: #fff;
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.InputWrapper_hint__3uFUF {
  color: #6c757d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}`, "",{"version":3,"sources":["webpack://./src/shared/InputWrapper/InputWrapper.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,kBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,MAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AACE;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AACJ;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 30px;\n  label {\n    color: #343a40;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 24px; /* 171.429% */\n    margin-bottom: 6px;\n  }\n  .req {\n    position: relative;\n    top: 0;\n    right: -4px;\n    color: #ff4b4b;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 24px;\n  }\n\n  input {\n    outline: none;\n    padding: 18px 20px;\n    border-radius: 8px;\n    border: 1px solid #e8e8ef;\n    background: #fff;\n    color: #343a40;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px; /* 150% */\n  }\n}\n.hint {\n  color: #6c757d;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 171.429% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `InputWrapper_wrapper__LGsKe`,
	"req": `InputWrapper_req__kO8v5`,
	"hint": `InputWrapper_hint__3uFUF`
};
export default ___CSS_LOADER_EXPORT___;
