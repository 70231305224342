import s from "./ProjectMenu.module.scss";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useActiveProjectFromUrl } from "./hooks/useActiveProjectFromUrl";
import { useLoadProjects } from "./hooks/useLoadProjects ";
import { AddProjectBtn } from "./components/AddProjectBtn/AddProjectBtn";
import { ProjectList } from "./components/ProjectList/ProjectList";
import { useLoadCategories } from "./hooks/useLoadCategories";
import { OrganizationSelect } from "../../components/OrganizationSelect/OrganizationSelect";

export const ProjectMenu: FC = () => {
  const dispatch = useDispatch();

  useActiveProjectFromUrl(dispatch);
  useLoadProjects(dispatch);
  useLoadCategories(dispatch);

  return (
    <div className={s.wrapper}>
      <>
        <div className={s.organizationWrapper}>
          <OrganizationSelect />
          <span>Projects</span>
        </div>
      </>
      <ProjectList />
      <AddProjectBtn />
    </div>
  );
};
