import { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBottom } from '../../assets/svg/ArrowBottom';
import { Close } from '../../assets/svg/Close';
import { IdPlace } from '../../shared/IdPlace/IdPlace';
import { CustomRadioButton } from '../../components/CustomRadioButton/CustomRadioButton';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { DropDownCameraSettings } from '../../components/DropDownCameraSettings/DropDownCameraSettings';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { ICameraSettings, NestedKey } from '../../processes/modals/ModalAddCamera/ModalAddCamera';
import { addOrUpdateItemsAttribute, changeShowModal } from '../../redux/actions';
import updateAttributeApi from '../../processes/api/attributes/updateAttributeApi';
import createAttributeApi from '../../processes/api/attributes/createAttributeApi';
import { popUp } from '../../features/popUp/PopUp';
import { ANTI_ALIASING, BACKGROUND_TYPE, ROTATION_TYPE } from '../../utils/constants/settings';
import { cameraSchema } from '../../utils/validationSchemas/cameraSchema';
import { apiRoutes, optionsTag } from '../../utils/constants';
import { PasteButton } from '../../processes/modals/ModalAddCamera/components/PasteButton/PasteButton';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
import { CheckboxWrapper } from '../../shared/CheckboxWrapper/CheckboxWrapper';
import { getValueFromArray } from '../../utils/getValueFromArray';
import axiosInstance from '../../processes/api/instance';

import styles from './CameraSettings.module.scss'

export const CameraSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [cameraSettings, setCameraSettings] = useState<any>();
  const cameraId  = params?.id;
  const cameraMode  = location?.state?.mode;
  const stageId = location?.state?.stageId;
  const attrId = stageId;

  useEffect(() => {
    const settingsHeader =  document.getElementById("camera-setting-id");
    const settingsBack =  document.getElementById("camera-setting-back");
    const settingsFooter =  document.getElementById("camera-setting-footer");

    if(settingsHeader && settingsBack && settingsFooter) {
      document.documentElement.style.setProperty(
        '--settingsHeaderHeight',
        `${settingsHeader.clientHeight}px`
      );
      document.documentElement.style.setProperty(
        '--settingsBackHeight',
        `${settingsBack.clientHeight}px`
      );
      document.documentElement.style.setProperty(
        '--settingsFooterHeight',
        `${settingsFooter.clientHeight}px`
      );
    }
  }, [])

  useEffect(() => {
    if(cameraMode !== 'edit') return

    const getCameraSettings = async () => {
      try {
        const { data } = await axiosInstance.get(`${apiRoutes.RETRIEVE_CAMERA.url}/${cameraId}`);
        setCameraSettings(data)
      } catch(e: any) {
        console.error(e.statusText);
      }
    }

    getCameraSettings();
  }, [])

  const initialValues = {
    cameraType: cameraSettings?.cameraType || 'new',
    name: cameraSettings?.name || '',
    paramString:  cameraSettings?.paramString || '',
    position: {
      x: cameraSettings?.position.x || 0,
      y: cameraSettings?.position.y || 0,
      z: cameraSettings?.position.z || 0
    },
    rotation: {
      roll: cameraSettings?.rotation.roll || 0,
      pitch: cameraSettings?.rotation.pitch || 0,
      yaw: cameraSettings?.rotation.yaw || 0,
    },
    productRotation: {
      roll: cameraSettings?.productRotation.roll || 0,
      pitch: cameraSettings?.productRotation.pitch || 0,
      yaw: cameraSettings?.productRotation.yaw || 0,
    },
    rotationAngle: cameraSettings?.rotationAngle || 360,
    fovCorrection: cameraSettings?.fovCorrection || 0,
    fov: cameraSettings?.fov || 90,
    background: 0,
    rotationType: getValueFromArray(ROTATION_TYPE, cameraSettings?.rotationType) || ROTATION_TYPE[0].value,
    rotationSteps: cameraSettings?.rotationSteps || 12,
    nearClipPlane: cameraSettings?.nearClipPlane || 10.0,
    exposure: (cameraSettings?.exposure || cameraSettings?.exposure === 0) ? cameraSettings?.exposure : null,
    renderWidth: cameraSettings?.renderWidth || 1920,
    renderHeight: cameraSettings?.renderHeight || 1080,
    backgroundType: getValueFromArray(BACKGROUND_TYPE, cameraSettings?.backgroundType) || BACKGROUND_TYPE[0].value,
    backgroundColor: cameraSettings?.backgroundColor || "ffffff",
    renderShadow: Boolean(cameraSettings?.renderShadow),
    renderDimensions: cameraSettings?.renderDimensions || false,
    dimensionsColor: cameraSettings?.dimensionsColor || "000000",
    lineOffset: cameraSettings?.lineOffset || 3,
    lineWidth: cameraSettings?.lineWidth || 1,
    capsLength: cameraSettings?.capsLength || 0,
    fontSize: cameraSettings?.fontSize || 14,
    fontName: cameraSettings?.fontName || '',
    imageWidth: cameraSettings?.imageWidth || 1920,
    imageHeight: cameraSettings?.imageHeight || 1080,
    renderType: cameraSettings?.renderType ||  "raster",
    imageType: cameraSettings?.imageType || "png",
    renderWarmup: cameraSettings?.renderWarmup || 10,
    antiAliasing: getValueFromArray(ANTI_ALIASING, cameraSettings?.antiAliasing),
  }

  const handleSubmit = async (values: any) => {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    const {fov, rotationSteps, position, rotation, productRotation, rotationType, exposure, nearClipPlane, backgroundType, renderWarmup, antiAliasing } = values;

    const rotationTypeValue =  ROTATION_TYPE.filter((item) => {
      return Number(item.value) === rotationType
    })[0]?.name;

    const backgroundTypeValue =  BACKGROUND_TYPE.filter((item) => {
      return Number(item.value) === backgroundType
    })[0]?.name;

    const antiAliasingValue =  ANTI_ALIASING.filter((item) => {
      return Number(item.value) === antiAliasing
    })[0]?.name;

    const cameraValues = {
      ...values,
      // transform
        stageId: Number(attrId || 1),
        position: {
          x: Number(position.x),
          y: Number(position.y),
          z: Number(position.z),
        },
        rotation: {
          roll: Number(rotation.roll),
          pitch: Number(rotation.pitch),
          yaw: Number(rotation.yaw),
        },
        productRotation: {
          roll: Number(productRotation.roll),
          pitch: Number(productRotation.pitch),
          yaw: Number(productRotation.yaw),
        },
        rotationType: rotationTypeValue,
        rotationSteps: Number(rotationSteps),
      // transform

      // lens
      fov: Number(fov),
      nearClipPlane: Number(nearClipPlane),
      exposure: exposure && exposure >= 0 ? Number(exposure) : null,
      // lens

      // background
      backgroundType: backgroundTypeValue,
      // background

      // render settings
      renderWarmup: Number(renderWarmup),
      antiAliasing: antiAliasingValue,
      // render settings
    }

      // редагуємо
      if (cameraMode === "edit") {

        const updatedOption = await updateAttributeApi({
          id: Number(cameraId),
          values: cameraValues,
          url: optionsTag["cameras"].update.url,
          request: optionsTag["cameras"].update.request,
        });
        if (updatedOption) {
          dispatch(
            addOrUpdateItemsAttribute({
              attrId: attrId,
              itemId: updatedOption.id,
              attrName: "stages",
              attrValue: updatedOption,
              type: "update",
            })
          );

          popUp({
            type: "success",
            message: `camera ${values.name} successfully changed`,
          });
        }
      } else {
        // створюємо
        const newAttr = await createAttributeApi({
          values: {
            ...cameraValues,
          },
          url: optionsTag["cameras"].create.url,
          request: optionsTag["cameras"].create.request,
        });
        if (newAttr) {
          dispatch(
            addOrUpdateItemsAttribute({
              attrId: attrId,
              itemId: newAttr.id,
              attrName: "stages",
              attrValue: newAttr,
              type: "add",
            })
          );
          popUp({
            type: "success",
            message: "Camera has been successfully created",
          });
        }
      }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: cameraSchema
  });

  const handleOnChange = (field: NestedKey<ICameraSettings> | string, value: string) => {
    if(field === 'exposure') {
      const exposureValue = value.length !== 0 ? value : null;
      formik.setFieldValue(field, exposureValue)
    }  else {
      formik.setFieldValue(String(field), value)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} id="camera-setting-id">
        <div className={styles.cameraName}>

        <button onClick={() => navigate(-1)}>
          <Close />
        </button>

        {cameraSettings?.name } Config
        </div>
        <IdPlace  id={cameraId} />
      </div>
      <div className={styles.back} id="camera-setting-back">
        <button onClick={() => navigate(-1)}>
          <ArrowBottom /> Back
        </button>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formWrapper}>
          <div className={styles.settingsBody}>
            <div className={styles.item}>
              <div className={styles.preferences}>
                <div className={styles.radioButtons}>
                  <CustomRadioButton 
                    name="cameraType"
                    id="1" 
                    value="New camera"
                    checked={formik.values.cameraType === "new"} 
                    onChange={() => handleOnChange("cameraType", "new")}
                  />
                  <CustomRadioButton
                    name="cameraType"
                    id="2"
                    value="New from 360"
                    checked={formik.values.cameraType === "new360"} 
                    onChange={() => handleOnChange("cameraType", "new360")}
                  />
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Name</span>
                  <span className={styles.required}>*</span>
                </div>
              </div>
              <div className={styles.preferences}>
                <div className={styles.inputWrapper}>
                  <CustomInput
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={formik.values?.name}
                    onChange={(e) => handleOnChange('name', e.target.value)}
                  />
                  {formik.errors.name ? <div className={styles.error}>{String(formik.errors.name)}</div>: null}
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Parameters</span>
                </div>
              </div>
              <div className={styles.preferences}>
                <div className={styles.inputWrapper}>
                  <CustomInput
                    type="text"
                    name="paramString"
                    placeholder="Enter parameters"
                    value={formik.values?.paramString}
                    onChange={(e) => handleOnChange('paramString', e.target.value)}
                  />
                  {formik.errors.paramString ? <div className={styles.error}>{String(formik.errors.paramString)}</div>: null}
                </div>
              </div>
            </div>
          </div>

          <DropDownCameraSettings headerTitle="Transform" name='transform' values={formik.values} handleChangeValue={handleOnChange}>
            <div className={styles.fieldWrapper}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Position</span>
                  <span className={styles.required}>*</span>
                </div>
                <PasteButton fieldName="position" onChange={handleOnChange} />
              </div>
              <div className={styles.fieldGroupWrapper}>
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      X-axis
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.position?.x}
                    type="string"
                    placeholder="X"
                    name="position.x"
                    id="position.x"
                    onChange={(e) => handleOnChange('position.x', e.target.value)}
                    />
                  {formik.errors.position?.x ? <div className={styles.error}>{String(formik.errors.position?.x)}</div>: null}
                </div>
    
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      Y-axis
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.position?.y}
                    type="string"
                    placeholder="Y"
                    name="position.y"
                    id="position.y"
                    onChange={(e) => handleOnChange('position.y', e.target.value)}
                    />
                  {formik.errors.position?.y ? <div className={styles.error}>{String(formik.errors.position?.y)}</div>: null}
                </div>
    
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      Z-axis
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.position?.z}
                    type="string"
                    placeholder="Y"
                    name="position.z"
                    id="position.z"
                    onChange={(e) => handleOnChange('position.z', e.target.value)}
                    />
                  {formik.errors.position?.z ? <div className={styles.error}>{String(formik.errors.position?.z)}</div>: null}
                </div>
              </div>


            </div>
    
            <div className={styles.fieldWrapper}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Rotation</span>
                  <span className={styles.required}>*</span>
                </div>
                <PasteButton fieldName="rotation" onChange={handleOnChange} />
              </div>
    
              <div className={styles.fieldGroupWrapper}>
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      Roll
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.rotation?.roll}
                    type="string"
                    placeholder="Roll"
                    name="rotation.roll"
                    id="rotation.roll"
                    onChange={(e) => handleOnChange('rotation.roll', e.target.value)}
                    />
                  {formik.errors.rotation?.roll ? <div className={styles.error}>{String(formik.errors.rotation?.roll)}</div>: null}
                </div>
    
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      Pitch
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.rotation?.pitch}
                    type="string"
                    placeholder="Pitch"
                    name="rotation.pitch"
                    id="rotation.pitch"
                    onChange={(e) => handleOnChange('rotation.pitch', e.target.value)}
                    />
                  {formik.errors.rotation?.pitch ? <div className={styles.error}>{String(formik.errors.rotation?.pitch)}</div>: null}
                </div>
    
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      Yaw
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.rotation?.yaw}
                    type="string"
                    placeholder="Yow"
                    name="rotation.yaw"
                    id="rotation.yaw"
                    onChange={(e) => handleOnChange('rotation.yaw', e.target.value)}
                    />
                  {formik.errors.rotation?.yaw ? <div className={styles.error}>{String(formik.errors.rotation?.yaw)}</div>: null}
                </div>
              </div>
            </div>
    
            <div className={styles.fieldWrapper}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Product Rotation</span> <span className={styles.required}>*</span>
                </div>
                <PasteButton fieldName="productRotation" onChange={handleOnChange} />
              </div>
    
              <div className={styles.fieldGroupWrapper}>
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      Roll
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.productRotation?.roll}
                    type="string"
                    placeholder="Roll"
                    name="productRotation.roll"
                    id="productRotation.roll"
                    onChange={(e) => handleOnChange('productRotation.roll', e.target.value)}
                    />
                  {formik.errors.productRotation?.roll ? <div className={styles.error}>{String(formik.errors.productRotation?.roll)}</div>: null}
                </div>
    
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      Pitch
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.productRotation?.pitch}
                    type="string"
                    placeholder="Pitch"
                    name="productRotation.pitch"
                    id="productRotation.pitch"
                    onChange={(e) => handleOnChange('productRotation.pitch', e.target.value)}
                    />
                  {formik.errors.productRotation?.pitch ? <div className={styles.error}>{String(formik.errors.productRotation?.pitch)}</div>: null}
                </div>
    
                <div className={styles.fieldGroupItem}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      Yow
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.productRotation?.yaw}
                    type="string"
                    placeholder="Yaw"
                    name="productRotation.yaw"
                    id="productRotation.yaw"
                    onChange={(e) => handleOnChange('productRotation.yaw', e.target.value)}
                    />
                  {formik.errors.productRotation?.yaw ? <div className={styles.error}>{String(formik.errors.productRotation?.yaw)}</div>: null}
                </div>
              </div>

              <div className={styles.fieldGroupWrapperHalf}>
                <div className={styles.fieldWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      <span>Rotation type</span><span className={styles.required}>*</span>
                    </div>
                  </div>
                  <CustomSelect 
                    name="rotationType"
                    options={ROTATION_TYPE}
                    value={Number(formik.values.rotationType)}
                    placeholder="Select rotation type"
                    onChange={formik.setFieldValue}
                  />
                  {formik.errors.rotationType ? <div className={styles.error}>{String(formik.errors.rotationType)}</div>: null}
                </div>
      
                <div className={styles.fieldWrapper}>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    <span>360° steps</span>
                  </div>
                </div>
                  <CustomInput
                    value={formik.values.rotationSteps}
                    type="number"
                    placeholder="Enter value"
                    name="rotationSteps"
                    id="rotationSteps"
                    min={1}
                    disabled={Number(formik.values.rotationType) === 0}
                    onChange={(e) => handleOnChange('rotationSteps', e.target.value)}
                  />
                  {formik.errors.rotationSteps ? <div className={styles.error}>{String(formik.errors.rotationSteps)}</div>: null}
                </div>
              </div>

              <div className={styles.fieldGroupWrapperHalf}>
                <div className={styles.fieldWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      <span>Rotation Angle </span>
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.rotationAngle}
                    type="text"
                    placeholder="Enter value"
                    name="rotationAngle"
                    id="rotationAngle"
                    disabled={Number(formik.values.rotationType) === 0}
                    onChange={(e) => handleOnChange('rotationAngle', e.target.value)}
                  />
                  {formik.errors.rotationAngle ? <div className={styles.error}>{String(formik.errors.rotationAngle)}</div>: null}
                </div>
      
                <div className={styles.fieldWrapper}>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    <span>FOV Correction</span>
                  </div>
                </div>
                  <CustomInput
                    value={formik.values.fovCorrection}
                    type="text"
                    placeholder="Enter value"
                    name="fovCorrection"
                    id="fovCorrection"
                    onChange={(e) => handleOnChange('fovCorrection', e.target.value)}
                  />
                  {formik.errors.fovCorrection ? <div className={styles.error}>{String(formik.errors.fovCorrection)}</div>: null}
                </div>
              </div>
            </div>
          </DropDownCameraSettings>

          <DropDownCameraSettings headerTitle="Lens" name='lens' values={formik.values}  handleChangeValue={handleOnChange}>
            <div className={styles.item}>
              <div className={styles.fieldWrapper}>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    <span>FOV</span><span className={styles.required}>*</span>
                  </div>
                </div>
                <CustomInput
                  value={formik.values.fov}
                  placeholder="FOV"
                  name="fov"
                  id="fov"
                  onChange={(e) => handleOnChange('fov', e.target.value)}
                />
                {formik.errors.fov ? <div className={styles.error}>{String(formik.errors.fov)}</div>: null}

                <div className={styles.fieldGroupWrapperHalf}>
                  <div className={styles.fieldWrapper}>
                    <div className={styles.titleWrapper}>
                      <div className={styles.title}>
                        <span>Near clip plane</span><span className={styles.required}>*</span>
                      </div>
                    </div>
                    <CustomInput
                      value={formik.values.nearClipPlane}
                      placeholder="Enter near clip plane"
                      name="nearClipPlane"
                      id="nearClipPlane"
                      min={1}
                      max={12}
                      onChange={(e) => handleOnChange('nearClipPlane', e.target.value)}
                    />
                    {formik.errors.nearClipPlane ? <div className={styles.error}>{String(formik.errors.nearClipPlane)}</div>: null}
                  </div>
        
                  <div className={styles.fieldWrapper}>
                    <div className={styles.titleWrapper}>
                      <div className={styles.title}>
                        <span>Exposure</span>
                      </div>
                      </div>
                        <CustomInput
                          value={formik.values.exposure}
                          placeholder="Enter value"
                          name="exposure"
                          id="exposure"
                          onChange={(e) => handleOnChange('exposure', e.target.value)}
                        />
                        {formik.errors.exposure ? <div className={styles.error}>{String(formik.errors.exposure)}</div>: null}
                      </div>
                  </div>
              </div>
            </div>
          </DropDownCameraSettings>

          <DropDownCameraSettings headerTitle="Background" name='background' values={formik.values} handleChangeValue={handleOnChange}>
           <div className={styles.item}>
            <div className={styles.fieldWrapper}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Background type</span>
                </div>
              </div>
              <CustomSelect
                options={BACKGROUND_TYPE}
                name="backgroundType"
                placeholder="choose background"
                value={formik.values.backgroundType || BACKGROUND_TYPE[0].value}
                onChange={formik.setFieldValue} 
                />
              {formik.errors.backgroundType ? <div className={styles.error}>{String(formik.errors.backgroundType)}</div>: null}
            </div>

            <div className={styles.fieldWrapper}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Color sRGB</span>{formik.values.backgroundType === 2 ? (<span className={styles.required}>*</span>) : null}
                </div>
              </div>
              <CustomInput
                value={formik.values.backgroundColor}
                placeholder="Enter background color"
                name="backgroundColor"
                id="backgroundColor"
                disabled={formik.values.backgroundType !== 2}
                onChange={(e) => handleOnChange('backgroundColor', e.target.value)}
              />
              {formik.errors.backgroundColor ? <div className={styles.error}>{String(formik.errors.backgroundColor)}</div>: null}
            </div>

              <div className={styles.fieldWrapper}>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    <span>Render shadow</span>
                  </div>
                </div>
                <CheckboxWrapper
                  value={formik.values.renderShadow}
                  name="renderShadow"
                  title="Render shadow"
                  setValue={handleOnChange}
                  disabled={formik.values.backgroundType === 0}
                  isCameraSetting={true}
                />
                <input type="checkbox" name="renderShadow" className={styles.hiddenInput} checked={!!formik.values.renderShadow} />
                {formik.errors.renderShadow ? <div className={styles.error}>{String(formik.errors.renderShadow)}</div>: null}
              </div>
            </div>
          </DropDownCameraSettings>

          <DropDownCameraSettings headerTitle="Dimensions" name='dimensions' values={formik.values} handleChangeValue={handleOnChange}>
            <div className={styles.item}>
            <div className={styles.fieldWrapper}>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    <span>Render dimensions</span>
                  </div>
                </div>
                <CheckboxWrapper
                  value={formik.values.renderDimensions}
                  name="renderDimensions"
                  title="Render dimensions"
                  setValue={handleOnChange}
                  isCameraSetting={true}
                />
                <input type="checkbox" name="renderDimensions" className={styles.hiddenInput} checked={!!formik.values.renderDimensions} />
                {formik.errors.renderDimensions ? <div className={styles.error}>{String(formik.errors.renderDimensions)}</div>: null}
              </div>

              <div className={styles.fieldGroupWrapperHalf}>
                <div className={styles.fieldWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      <span>Color sRGB</span>{formik.values.renderDimensions ? (<span className={styles.required}>*</span>) : null }
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.dimensionsColor}
                    placeholder="Enter color"
                    name="dimensionsColor"
                    id="dimensionsColor"
                    disabled={!formik.values.renderDimensions}
                    onChange={(e) => handleOnChange('dimensionsColor', e.target.value)}
                  />
                  {formik.errors.dimensionsColor ? <div className={styles.error}>{String(formik.errors.dimensionsColor)}</div>: null}
                </div>

                <div className={styles.fieldWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      <span>Line offset</span>{formik.values.renderDimensions ? (<span className={styles.required}>*</span>) : null }
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.lineOffset}
                    placeholder="Line offset"
                    name="lineOffset"
                    id="lineOffset"
                    disabled={!formik.values.renderDimensions}
                    onChange={(e) => handleOnChange('lineOffset', e.target.value)}
                  />
                  {formik.errors.lineOffset ? <div className={styles.error}>{String(formik.errors.lineOffset)}</div>: null}
                </div>
              </div>

              <div className={styles.fieldGroupWrapperHalf}>
                <div className={styles.fieldWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      <span>Line width</span>{formik.values.renderDimensions ? (<span className={styles.required}>*</span>) : null }
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.lineWidth}
                    placeholder="10"
                    name="lineWidth"
                    id="lineWidth"
                    disabled={!formik.values.renderDimensions}
                    onChange={(e) => handleOnChange('lineWidth', e.target.value)}
                  />
                  {formik.errors.lineWidth ? <div className={styles.error}>{String(formik.errors.lineWidth)}</div>: null}
                </div>

                <div className={styles.fieldWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      <span>Caps length</span>{formik.values.renderDimensions ? (<span className={styles.required}>*</span>) : null }
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.capsLength}
                    placeholder="0"
                    name="capsLength"
                    id="capsLength"
                    disabled={!formik.values.renderDimensions}
                    onChange={(e) => handleOnChange('capsLength', e.target.value)}
                  />
                  {formik.errors.capsLength ? <div className={styles.error}>{String(formik.errors.capsLength)}</div>: null}
                </div>
              </div>

              <div className={styles.fieldGroupWrapperHalf}>
                <div className={styles.fieldWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      <span>Font Size</span>
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.fontSize}
                    type="number"
                    name="fontSize"
                    id="fontSize"
                    disabled={!formik.values.renderDimensions}
                    onChange={(e) => handleOnChange('fontSize', e.target.value)}
                  />
                  {formik.errors.fontSize ? <div className={styles.error}>{String(formik.errors.fontSize)}</div>: null}
                </div>

                <div className={styles.fieldWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                      <span>Font name</span>
                    </div>
                  </div>
                  <CustomInput
                    value={formik.values.fontName}
                    placeholder='Enter font name'
                    type="string"
                    name="fontName"
                    id="fontName"
                    disabled={!formik.values.renderDimensions}
                    onChange={(e) => handleOnChange('fontName', e.target.value)}
                  />
                  {formik.errors.fontName ? <div className={styles.error}>{String(formik.errors.fontName)}</div>: null}
                </div>
              </div>
            </div>
          </DropDownCameraSettings>

          <DropDownCameraSettings headerTitle="Render settings" name='renderSettings'  values={formik.values} handleChangeValue={handleOnChange}>
            <div className={styles.item}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span> Render image resolution</span><span className={styles.required}>*</span>
                </div>
              </div>
              <div className={styles.fieldGroupWrapperHalf}>
                <div className={styles.wrapper}>
                  <CustomInput
                    value={formik.values.renderWidth}
                    type="number"
                    placeholder="Width (px)"
                    name="renderWidth"
                    id="renderWidth"
                    min={320}
                    max={7680}
                    onChange={(e) => handleOnChange('renderWidth', e.target.value)}
                  />
                  {formik.errors.renderWidth ? <div className={styles.error}>{String(formik.errors.renderWidth)}</div>: null}
                </div>
                <div className={styles.wrapper}>
                  <CustomInput
                    value={formik.values.renderHeight}
                    type="number"
                    placeholder="Height (px)"
                    name="renderHeight"
                    id="renderHeight"
                    min={320}
                    max={7680}
                    onChange={(e) => handleOnChange('renderHeight', e.target.value)}
                  />
                  {formik.errors.renderWidth ? <div className={styles.error}>{String(formik.errors.renderWidth)}</div>: null}
                </div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Final image resolution</span><span className={styles.required}>*</span>
                </div>
              </div>
              <div className={styles.fieldGroupWrapperHalf}>
              <div className={styles.wrapper}>
                  <CustomInput
                    value={formik.values.imageWidth}
                    type="number"
                    placeholder="Width (px)"
                    name="imageWidth"
                    id="imageWidth"
                    min={128}
                    max={7680}
                    onChange={(e) => handleOnChange('imageWidth', e.target.value)}
                  />
                  {formik.errors.imageWidth ? <div className={styles.error}>{String(formik.errors.imageWidth)}</div>: null}

                </div>
                <div className={styles.wrapper}>
                  <CustomInput
                    value={formik.values.imageHeight}
                    type="number"
                    placeholder="Height (px)"
                    name="imageHeight"
                    id="imageHeight"
                    min={128}
                    max={7680}
                    onChange={(e) => handleOnChange('imageHeight', e.target.value)}
                  />
                  {formik.errors.imageHeight ? <div className={styles.error}>{String(formik.errors.imageWidth)}</div>: null}
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Preferred render type</span><span className={styles.required}>*</span>
                </div>
              </div>
              <div className={styles.preferences}>
                <div className={styles.radioButtons}>
                  <CustomRadioButton 
                    name="renderType"
                    id="1" 
                    value="Raster"
                    checked={formik.values.renderType === "raster"} 
                    onChange={() => handleOnChange("renderType", "raster")} 
                  />
                  <CustomRadioButton
                    name="renderType"
                    id="2"
                    value="PathTracing"
                    checked={formik.values.renderType === "pathTracing"}
                    onChange={() => handleOnChange("renderType", "pathTracing")}
                  />
                </div>
              </div>
            </div>
              
            <div className={styles.item}>
              <div className={styles.fieldWrapper}>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    <span>Render warmup</span><span className={styles.required}>*</span>
                  </div>
                </div>
                <CustomInput
                  type="number"
                  value={formik.values.renderWarmup}
                  min={1}
                  placeholder="Enter background color"
                  name="renderWarmup"
                  id="renderWarmup"
                  onChange={(e) => handleOnChange('renderWarmup', e.target.value)}
                />
                {formik.errors.renderWarmup ? <div className={styles.error}>{String(formik.errors.renderWarmup)}</div>: null}
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <span>Preferred image type</span><span className={styles.required}>*</span>
                </div>
              </div>
              <div className={styles.preferences}>
                <div className={styles.radioButtons}>
                  <CustomRadioButton 
                    name="imageType"
                    id="1" 
                    value=".png"
                    checked={formik.values.imageType === "png"}
                    onChange={() => handleOnChange("imageType", "png")} 
                  />
                  <CustomRadioButton
                    name="imageType"
                    id="2"
                    value=".jpg"
                    checked={formik.values.imageType === "jpg"}
                    onChange={() => handleOnChange("imageType", "jpg")}
                  />
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.fieldWrapper}>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    <span>Anti Aliasing</span><span className={styles.required}>*</span>
                  </div>
                </div>
                <CustomSelect
                  options={ANTI_ALIASING}
                  name="antiAliasing"
                  placeholder="choose antiAliasing"
                  value={formik.values.antiAliasing || 0}
                  onChange={formik.setFieldValue} 
                  />
                {formik.errors.antiAliasing ? <div className={styles.error}>{String(formik.errors.antiAliasing)}</div>: null}
              </div>
            </div>
          </DropDownCameraSettings>
        </div>

        <div className={styles.footer} id="camera-setting-footer">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}
