import { AxiosResponse } from "axios";
import { apiRoutes } from "../../../utils/constants"
import axiosInstance from "../instance"

export interface IUpdateCombiningOption {
  id: number,
  optionId: number,
  dependencies: {id: number, type: string}[],
  layerOrder: number,
}
// @ts-ignore
export const updateCompbiningOptionAPI = async (data: IUpdateCombiningOption[], projectId: number):  Promise<AxiosResponse<void>> => {
  const URL = `${apiRoutes.UPDATE_PRODUCT.url}/${projectId}/options/combining`
  try {
    const response = await axiosInstance.put<void>(URL, data);
    return response
  } catch (error) {
    console.error(`Error while fetching ${URL}, 'error'`);
    // throw error;
  }
}