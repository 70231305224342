import { FC } from "react";

export const PastIcon: FC = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.667 3.16699H12.0003C12.3539 3.16699 12.6931 3.30747 12.9431 3.55752C13.1932 3.80756 13.3337 4.1467 13.3337 4.50033V13.8337C13.3337 14.1873 13.1932 14.5264 12.9431 14.7765C12.6931 15.0265 12.3539 15.167 12.0003 15.167H4.00033C3.6467 15.167 3.30756 15.0265 3.05752 14.7765C2.80747 14.5264 2.66699 14.1873 2.66699 13.8337V4.50033C2.66699 4.1467 2.80747 3.80756 3.05752 3.55752C3.30756 3.30747 3.6467 3.16699 4.00033 3.16699H5.33366" stroke="#6C757D" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.99967 1.83301H5.99967C5.63148 1.83301 5.33301 2.13148 5.33301 2.49967V3.83301C5.33301 4.2012 5.63148 4.49967 5.99967 4.49967H9.99967C10.3679 4.49967 10.6663 4.2012 10.6663 3.83301V2.49967C10.6663 2.13148 10.3679 1.83301 9.99967 1.83301Z" stroke="#6C757D" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
