import { IStreamSettingsData, IUpdateImageSettingsData, IWebGlSettingsData } from "../reducers/settings";

export const SETTINGS_REDUCER = {
  SET_IMAGE_SETTING: "[setting]: set image settings",
  SET_WEBGL_SETTING: "[setting]: set webgl settings",
  SET_STREAM_SETTING: "[setting]: set stream settings",
  SET_APPS_SETTING: "[setting]: set apps settings",
};

export const setImageSettings = ({...data}: IUpdateImageSettingsData) => {
  return {
    type: SETTINGS_REDUCER.SET_IMAGE_SETTING,
    payload: data,
  };
};

export const setWebGlSettings = ({...data}: IWebGlSettingsData) => {
  return {
    type: SETTINGS_REDUCER.SET_WEBGL_SETTING,
    payload: data,
  };
};

export const setStreamSettings = ({...data}: IStreamSettingsData) => {
  return {
    type: SETTINGS_REDUCER.SET_STREAM_SETTING,
    payload: data,
  };
};