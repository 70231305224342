import { FC, useEffect } from "react";
import s from "./Index.module.scss";
import { MobileHint } from "../../shared/MobileHint/MobileHint";
import { checkTokenAndRefresh } from "../../features/functions/checkTokenAndRefresh";
import { MainLayout } from "../../widgets/MainLayout/MainLayout";
import { addOrganizationtThunk } from "../../redux/thunks/addOrganization";
import { useDispatch } from "react-redux";
import { useAxiosErrorHandling } from "../../utils/hooks/useAxiosErrorHandling";

export const App: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      await checkTokenAndRefresh();
      await dispatch(addOrganizationtThunk());
    })();
  },[])
  
  useAxiosErrorHandling();

  return (
    <>
      <MainLayout>
        <div className={s.wrapper}></div>
      </MainLayout>
      <MobileHint />
    </>
  );
};
