// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeadProjectInfo_wrapper__gydu6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  border-bottom: 1px solid #e8e8ef;
  height: 50px;
  width: 100%;
}

.HeadProjectInfo_title__j6W5L {
  margin-left: 12px;
  color: #343A40;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
}

.HeadProjectInfo_info__qrliW {
  display: flex;
  align-items: center;
}

.HeadProjectInfo_close__VMLE- {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.HeadProjectInfo_close__VMLE- svg {
  transition: all 0.2s;
}
.HeadProjectInfo_close__VMLE-:hover svg {
  transform: scale(1.1);
}

.HeadProjectInfo_tools__Begit {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/widgets/HeadProjectInfo/HeadProjectInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,gCAAA;EACA,YAAA;EACA,WAAA;AACF;;AACA;EACE,iBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAEF;;AAAA;EACE,aAAA;EACA,mBAAA;AAGF;;AADA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;AAIF;AAHE;EACE,oBAAA;AAKJ;AAFI;EACE,qBAAA;AAIN;;AACA;EACE,aAAA;EACA,mBAAA;AAEF","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 24px 16px;\n  border-bottom: 1px solid #e8e8ef;\n  height: 50px;\n  width: 100%;\n}\n.title {\n  margin-left: 12px;\n  color: #343A40;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 24px; /* 100% */\n}\n.info {\n  display: flex;\n  align-items: center;\n}\n.close {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  svg {\n    transition: all 0.2s;\n  }\n  &:hover {\n    svg {\n      transform: scale(1.1);\n    }\n  }\n}\n\n.tools {\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `HeadProjectInfo_wrapper__gydu6`,
	"title": `HeadProjectInfo_title__j6W5L`,
	"info": `HeadProjectInfo_info__qrliW`,
	"close": `HeadProjectInfo_close__VMLE-`,
	"tools": `HeadProjectInfo_tools__Begit`
};
export default ___CSS_LOADER_EXPORT___;
