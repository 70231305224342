import { FC, useEffect, useState } from "react";
import s from "./AttributesTab.module.scss";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constants";
import { ButtonWrapper } from "../../shared/ButtonWrapper/ButtonWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveProject,
  getActiveTab,
  getAttributeCounts,
  getProjectById,
} from "../../redux/selectors/selectors";
import { setActiveTab } from "../../redux/actions";

type AttributesTabT = {
  buttonName?: string;
  createCollection?: any;
};
export const AttributesTab: FC<AttributesTabT> = ({ ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeProject = useSelector(getActiveProject);
  const activeTabInState = useSelector(getActiveTab);
  const project = useSelector(getProjectById(activeProject));

  const [activeTabLocal, setActiveTabLocal]: any = useState(activeTabInState);
  const { buttonName, createCollection } = props;
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  // Получаем первый элемент массива (часть строки до первого "/")
  const idTab = parts[2];
  const collectionIsHide =
    project &&
    project.attributesList.options.count === 0 &&
    project.attributesList.stages.count === 0
      ? true
      : false;
  const tabData = [
    {
      name: "Options",
      id: "options",
      width: "50px",
      count: project ? project.attributesList.options.count : 0,
      link: () => {
        setActiveTabLocal("options");
        navigate(`/${activeProject}${PAGES.OPTIONS}`);
      },
    },
    {
      name: "Stages",
      id: "stages",
      width: "43px",

      count: project ? project.attributesList.stages.count : 0,
      link: () => {
        setActiveTabLocal("stages");
        navigate(`/${activeProject}${PAGES.STAGES}`);
      },
    },

    {
      name: "Environment",
      id: "environment",
      width: "70px",
      count: project ? project.attributesList.environment.count : 0,
      link: () => {
        setActiveTabLocal("environment");
        navigate(`/${activeProject}${PAGES.ENVIRONMENT}`);
      },
    },
    {
      name: "Collections",
      id: "collections",
      width: "69px",

      count: project ? project.attributesList.collections.count : 0,
      link: () => {
        setActiveTabLocal("collections");
        navigate(`/${activeProject}${PAGES.COLLECTIONS}`);
      },
      disabled: true,
      // disabled: collectionIsHide,
    },
    {
      name: "Products",
      id: "products",
      width: "55px",
      count: project ? project.attributesList.products.count : 0,
      link: () => {
        setActiveTabLocal("products");
        navigate(`/${activeProject}${PAGES.PRODUCTS}`);
      },
    },
  ];

  useEffect(() => {
    if (activeTabLocal !== activeTabInState) {
      dispatch(setActiveTab({ id: activeTabLocal }));
    }
  }, [activeTabLocal]);

  useEffect(() => {
    if (idTab) {
      setActiveTabLocal(idTab);
    }
  }, [pathname]);

  return (
    <div className={s.wrapper}>
      <div className={s.tabs}>
        {tabData.map((tabItem: any, index: number) => {
          return (
            <button
              className={`${s.tab} ${tabItem.disabled && s.disabled} ${
                activeTabLocal === tabItem.id && s.active
              }`}
              key={`tab_${index}`}
              onClick={tabItem.link}
            >
              <span className={s.text} style={{ width: tabItem.width }}>
                {tabItem.name}
              </span>
              <span className={s.count}>{tabItem.count}</span>
            </button>
          );
        })}
      </div>
      {buttonName && (
        <div className={s.create}>
          <ButtonWrapper
            text={buttonName}
            clickEvent={createCollection}
            type="outlane"
            icon={true}
          />
        </div>
      )}
    </div>
  );
};
