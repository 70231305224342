import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import styles from './CustomInput.module.scss';

interface ICustomInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{};

export const CustomInput = ({ placeholder, id, name, type = "text", ...props }: ICustomInputProps) => {
  return (
    <input className={styles.input} type={type} placeholder={placeholder} name={name} id={id} {...props}/>
  )
}
