import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx';
import { getOrganizationList } from '../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveOrganizationId } from '../../redux/actions';
import s from './OrganizationSelect.module.scss'
import { ArrowBottom } from '../../assets/svg/ArrowBottom';

export const OrganizationSelect = () => {
  const dispatch = useDispatch();
  const organizationList = useSelector(getOrganizationList());
  const [selectedOption, setSelectedOption] = useState(organizationList[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(0);

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleSetActiveOrganization = (organizationId: number) => {
    dispatch(setActiveOrganizationId(organizationId));
  }

  const handleKeyDownTrigger = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setIsOpen(!isOpen);
    } else if (e.key === 'ArrowDown' && !isOpen) {
      e.preventDefault();
      setIsOpen(true);
    }
  };

  const handleKeyDownOption = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedOptionIndex((prev) => (prev !== null && prev < organizationList.length - 1 ? prev + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedOptionIndex((prev) => (prev !== null && prev > 0 ? prev - 1 : organizationList.length - 1));
    } else if (e.key === 'Enter' && focusedOptionIndex !== null) {
      e.preventDefault();
      handleOptionClick(organizationList[focusedOptionIndex]);
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setIsOpen(false);
    }
  };

  return (
    <div className={s["custom-select"]} ref={selectRef}>
    <div
      className={clsx({[s['select-trigger']]: true}, {[s['select-trigger-active']]: isOpen === true})}
      tabIndex={0}
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      onKeyDown={handleKeyDownTrigger}
    >
      {selectedOption ? selectedOption.name : 'Select an organization'}
      <span 
        className={clsx({[s.arrow]: true}, {[s['arrow-active']]: isOpen === true})}
        >
          <ArrowBottom />
        </span>
    </div>
    {isOpen && (
      // eslint-disable-next-line jsx-a11y/aria-activedescendant-has-tabindex
      <div
        className={s["select-options"]}
        role="listbox"
        aria-activedescendant={focusedOptionIndex !== null ? `option-${focusedOptionIndex}` : undefined}
        onKeyDown={handleKeyDownOption}
      >
        {organizationList.map((option, index) => (
          <div
            id={`option-${index}`}
            key={option.id}
            role="option"
            aria-selected={selectedOption?.id === option.id}
            className={clsx({[s.option]: true}, {[s.selected]: selectedOption?.id === option.id}, {[s.focused]: focusedOptionIndex === index})}
            tabIndex={focusedOptionIndex === index ? 0 : -1} // Only the focused option is tabbable
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              handleOptionClick(option);
              handleSetActiveOrganization(option.id);
            }}
            onMouseEnter={() => setFocusedOptionIndex(index)}
          >
            {option.name}
          </div>
        ))}
      </div>
    )}
  </div>
  )

}

