// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventBtn_edit__1rM1P {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.EventBtn_edit__1rM1P svg {
  margin-right: 4px;
}
.EventBtn_edit__1rM1P svg path {
  transition: all 0.2s;
}
.EventBtn_edit__1rM1P span {
  transition: all 0.2s;
  color: #7d7d8a;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.EventBtn_edit__1rM1P.EventBtn_delete__nHwif span {
  color: #E41F2A;
}
.EventBtn_edit__1rM1P:hover span {
  color: #31b27e;
}
.EventBtn_edit__1rM1P:hover svg path {
  fill: #31b27e;
}`, "",{"version":3,"sources":["webpack://./src/shared/EventBtn/EventBtn.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAAI;EACE,iBAAA;AAEN;AADM;EACE,oBAAA;AAGR;AAAI;EACE,oBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEN;AAEM;EACE,cAAA;AAAR;AAKM;EACE,cAAA;AAHR;AAMQ;EACE,aAAA;AAJV","sourcesContent":[".edit {\n    transition: all 0.2s;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    svg {\n      margin-right: 4px;\n      path {\n        transition: all 0.2s;\n      }\n    }\n    span {\n      transition: all 0.2s;\n      color: #7d7d8a;\n      text-align: center;\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: normal;\n    }\n      \n    &.delete {\n      span {\n        color: #E41F2A;\n      }\n    }\n\n    &:hover {\n      span {\n        color: #31b27e;\n      }\n      svg {\n        path {\n          fill: #31b27e;\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit": `EventBtn_edit__1rM1P`,
	"delete": `EventBtn_delete__nHwif`
};
export default ___CSS_LOADER_EXPORT___;
