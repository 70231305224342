import { getOrganizationsListAPI } from "../../processes/api/organizations/getOrganizationsList"
import { setOrganizationList } from "../actions";
import { AppDispatch } from "../store";

export const addOrganizationtThunk = (): any => {
  return async (dispatch: AppDispatch) => {
    try{
      const response = await getOrganizationsListAPI();

      dispatch(setOrganizationList(response.data))
    } catch (e: any) {
      console.log("errorMessage", e.message);
    }
  }
}
