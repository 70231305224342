import { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { PAGES, PAGES_SETTINGS_ROUTES } from '../utils/constants'
import { Loader } from '../processes/modals/Loader/Loader';
import { CameraSettings } from '../widgets/CameraSetting/CameraSettings';

const App = lazy(() => import("../pages/Index/Index"));
const Login = lazy(() => import("../pages/Login/Index"));
const Options = lazy(() => import("../pages/Options/Index"));
const Products = lazy(() => import("../pages/Products/Index"));
const Stages = lazy(() => import("../pages/Stages/Index"));
const Environment = lazy(() => import("../pages/Environment/Index"));
const Collections = lazy(() => import("../pages/Collections/Index"));
const Settingslayout = lazy(() => import("../components/settings/Settingslayout/Settingslayout"));
const ImageSettings = lazy(() => import("../components/settings/Image/ImageSettings"));
const WebGl = lazy(() => import("../components/settings/WebGl/WebGl"));
const Stream = lazy(() => import("../components/settings/Stream/Stream"));
const Apps = lazy(() => import("../components/settings/Apps/Apps"));
const ProjectLayout = lazy(() => import("../components/ProjectLayout/ProjectLayout"));

export const MainRouter = () => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route path={PAGES.PROJECTS} element={<App />} />
          <Route path={PAGES.LOGIN} element={<Login />} />
          <Route path={`:id/*`} element={<ProjectLayout />} >
            <Route index path={`${PAGES.OPTIONS}`} element={<Options />} />
            <Route path={`${PAGES.COLLECTIONS}`} element={<Collections />} />
            <Route path={`${PAGES.ENVIRONMENT}`} element={<Environment />} />
            <Route path={`${PAGES.PRODUCTS}`} element={<Products />} />
            <Route path={`${PAGES.STAGES}`} element={<Stages />} />
            <Route path={`${PAGES.STAGE_CAMERAS}/:attributeId`} element={<Stages />} />

            <Route path={`${PAGES.STAGES}/camera`} element={<CameraSettings />}>
              <Route index path={':id/*'} element={<CameraSettings />} />
            </Route>
          </Route>

          <Route path=":id/settings/*" element={<Settingslayout/>}>
            <Route index path={PAGES_SETTINGS_ROUTES.IMAGE} element={<ImageSettings />}/>
            <Route path={PAGES_SETTINGS_ROUTES.WEBGL} element={<WebGl />}/>
            <Route path={PAGES_SETTINGS_ROUTES.STREAM} element={<Stream />}/>
            <Route path={PAGES_SETTINGS_ROUTES.APPS} element={<Apps />}/>
            <Route path="*" element={<p>Page was not found</p>} />
          </Route>
  
          <Route path="*" element={<p>Page was not found</p>} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}
