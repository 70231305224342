import React from 'react'
import styles from "./CustomRadioButton.module.scss";

interface ICustomRadioButton {
  id: string;
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  onChange(): void;
}

export const CustomRadioButton = ({value, id, name, checked, disabled, onChange}: ICustomRadioButton) => {
  return (
    <label className={styles.container}>
      <input type="radio" id={id} name={name} value={value} checked={checked} disabled={disabled} onClick={onChange}/>
      <div className={styles.title}>{value}</div>
      <span className={styles.checkmark}></span>
    </label>
  )
}
