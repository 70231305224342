import { useEffect, useState } from 'react'
import style from './CustomSelect.module.scss'
import clsx from 'clsx'
import { ArrowBottom } from '../../assets/svg/ArrowBottom'

export interface ISelectItem {
  id: number; name: string, value: number,
}

interface ICustomSelect {
  options: ISelectItem[];
  name: string;
  value: number;
  icon?: string;
  placeholder: string;
  onChange(name: string, activeOption: number): void;
}

export const CustomSelect = ({options, value, placeholder, name, icon, onChange}: ICustomSelect) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<string>();

  const getCurrentName = (options: ISelectItem[], value: number): string => {
    return options.filter((item) => {
      return Number(item.value) === Number(value)
    })[0]?.name;
  }

  useEffect(() => {
    const currentName = getCurrentName(options, value);
    
    setActiveOption(currentName);
  }, [value])
  
  const handleSetActiveOption = (option: number) => {
    const currentName = getCurrentName(options, option);

    setActiveOption(currentName)
    onChange && onChange(name, option)
    setIsOpen(false);
  }

  return (
    <div className={style.wrapper} onClick={() => setIsOpen(!isOpen)}>
      <button className={clsx({[style.select]: true}, {[style['select-active']]: isOpen === true})} type="button" name={name}>
        <span>
          {activeOption || placeholder || 'Select resolution'}
        </span>

        <span 
          className={clsx({[style.arrow]: isOpen === false}, {[style['arrow-active']]: isOpen === true})}
        >
          <ArrowBottom />
        </span>
      </button>
    
    {isOpen ? (
      <div className={style.body}>
        <ul>
          {options.map((option, index) => {
            const optionValue = option.value;
            return (
              <li 
                key={index} 
                tabIndex={0}
                onClick={() => handleSetActiveOption(optionValue)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    handleSetActiveOption(optionValue)
                  }
                }}
              ><span>{option.name}{icon ? icon: null}</span></li>
            )
          })}
        </ul>
      </div>
    ) : null}
    </div>
  )
}
